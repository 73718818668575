import "./SearchBar.scss";

import get from "lodash/get";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import { searchProperties } from "../services/api";
import { isRunningInFront } from "../services/front";
import Button from "./Button";
import User from "./User";

class SearchBar extends Component {
  state = {
    searchInput: ""
  };

  handleOnChange = e => {
    this.setState({
      searchInput: e.target.value
    });
  };

  handleEnter = e => {
    if (e.key === "Enter") {
      this.handleRedirect();
    }
  };

  handleRedirect = () => {
    const { history } = this.props;
    const { searchInput } = this.state;
    const homeCode = searchInput.replace(/\s+/g, "").toUpperCase();

    this.setState({ searchInput: homeCode });

    const showError = message => {
      return history.push({
        pathname: "/not-found",
        error: message
      });
    };

    searchProperties({ home_code: homeCode })
      .then(({ data }) => {
        const airbnbLogin = data.property.airbnb_login;

        history.push(`/mailbox/${airbnbLogin}`);
        this.setState({ searchInput: "" });
      })
      .catch(error => {
        const errorMessage = get(
          error,
          "response.data.error",
          "Please try again."
        );

        return showError(errorMessage);
      });
  };

  render() {
    if (isRunningInFront) {
      return null;
    }

    const { searchInput } = this.state;
    const { history } = this.props;

    return (
      <div className="SearchBar">
        <div className="SearchBar__search">
          <input
            placeholder={"Search by home code"}
            onKeyPress={this.handleEnter}
            onChange={this.handleOnChange}
            value={searchInput}
          />

          <Button
            buttonType="positive"
            handleOnClick={this.handleRedirect}
            disabled={!searchInput}
            className="iconOnly"
          >
            <SearchIcon />
          </Button>
        </div>

        <User history={history} />
      </div>
    );
  }
}

export default withRouter(SearchBar);
