import "./Properties.scss";

import React from "react";

import ExpandableArea from "../../../components/ExpandableArea";
import Property from "./Property";

const Properties = ({ properties, listingId }) => {
  const isMultiPropertyAccount = () => properties && properties.length > 1;

  const multiPropertyDisplay = properties => {
    return (
      <ExpandableArea
        expandableTitle={<span>{properties.length} managed properties</span>}
        expandedContent={properties.map(property => (
          <Property
            property={property}
            listingId={listingId}
            showFilters={true}
            key={property.id}
          />
        ))}
      />
    );
  };

  if (!properties || properties.length === 0) {
    return null;
  }

  return (
    <div className="Properties" data-testid="properties">
      {isMultiPropertyAccount() ? (
        multiPropertyDisplay(properties)
      ) : (
        <Property
          property={properties[0]}
          listingId={listingId}
          showFilters={false}
        />
      )}
    </div>
  );
};

export default Properties;
