import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { getSuggestedResponse } from "../../services/api";
import { securelyParseJson } from "./utils";

export const useLocalStorageSuggestedResponse = (thread, booking, property) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { conversationId, threadId } = useParams();

  const id = conversationId
    ? `conversation-id:${conversationId}`
    : `thread-id:${threadId}`;

  const storageKey = `airbox:ai-suggested-message:${id}`;

  const parsedJson = useCallback(
    () => securelyParseJson(window.localStorage.getItem(storageKey)),
    [storageKey]
  );

  const storedSuggestedResponse = useCallback(
    () => parsedJson()?.suggestedResponse,
    [parsedJson]
  );

  const storedSuggestedResponseRecordId = useCallback(
    () => parsedJson()?.recordId,
    [parsedJson]
  );

  const isReadyToFetchSuggestion = useMemo(
    () => property?.id && thread?.posts,
    [thread?.posts, property?.id]
  );

  useEffect(() => {
    if (!isReadyToFetchSuggestion) {
      return;
    }

    const storedResponse = parsedJson();
    if (storedResponse) {
      if (
        storedResponse.messagesCount > 0 &&
        storedResponse.messagesCount === thread?.posts?.length
      ) {
        setLoading(false);

        return;
      } else {
        // Suggested response is outdated
        window.localStorage.removeItem(storageKey);
      }
    }

    setError(null);
    getSuggestedResponse({
      bookingId: booking?.id,
      messages: thread.posts,
      propertyId: property.id
    })
      .then(({ data }) => {
        setLoading(false);
        window.localStorage.setItem(
          storageKey,
          JSON.stringify({
            // Keep track of the number of messages in the thread
            // So if the thread has new messages, we fetch a new suggestion
            messagesCount: thread?.posts?.length,
            suggestedResponse: data.ai_generated_response,
            recordId: data.ai_performance_record_id
          })
        );
      })
      .catch(error => {
        setError(true);
        setLoading(false);
      });
  }, [
    property,
    thread,
    booking?.id,
    storageKey,
    storedSuggestedResponse,
    isReadyToFetchSuggestion,
    parsedJson
  ]);

  return {
    loading,
    error,
    getSuggestedResponse: storedSuggestedResponse,
    getSuggestedResponseRecordId: storedSuggestedResponseRecordId
  };
};
