class GoogleAuth {
  getAuthInstance() {
    return window.gapi.auth2.getAuthInstance();
  }

  getCurrentUser() {
    return this.getAuthInstance().currentUser.get();
  }

  isSignedIn() {
    return this.getCurrentUser().isSignedIn();
  }

  getUserProfile() {
    const userProfile = this.getCurrentUser().getBasicProfile();

    return {
      name: userProfile.getName(),
      profileImage: userProfile.getImageUrl(),
      email: userProfile.getEmail()
    };
  }

  getAuthToken() {
    return new Promise((resolve, reject) => {
      if (!this.isSignedIn()) {
        return reject();
      }

      const authData = this.getCurrentUser().getAuthResponse();

      if (authData.expires_in > 0) {
        resolve(authData.id_token);
      } else {
        this.getCurrentUser()
          .reloadAuthResponse()
          .then(refreshedAuthData => {
            resolve(refreshedAuthData.id_token);
          })
          .catch(() => {
            reject();
          });
      }
    });
  }

  signOut() {
    return new Promise((resolve, reject) => {
      this.getAuthInstance()
        .signOut()
        .then(() => {
          this.getAuthInstance().disconnect();
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export const auth = new GoogleAuth();
