import "./GuestFlagAlert.scss";

import { format } from "date-fns";
import React from "react";

import Alert from "../../../components/Alert";
import { DATETIME_FORMAT } from "../../../utils/constants";

const GuestFlagAlert = ({ flag }) => {
  if (!flag) {
    return null;
  }

  const flagInfo = `${format(flag.flagged_at, DATETIME_FORMAT)}${
    flag.flagged_by !== "null" ? ` by ${flag.flagged_by}` : ""
  }`;

  let status;
  if (flag.flag_type === "warning") {
    status = "pending";
  } else if (flag.flag_type === "block") {
    status = "fail";
  } else {
    status = "info";
  }

  return (
    <div className="GuestFlagAlert">
      <Alert
        title={`Guest flagged as ${flag.flag_type.toUpperCase()}`}
        message={flag.flag_reason}
        status={status}
        extra={flagInfo}
        type="block"
      />
    </div>
  );
};

export default GuestFlagAlert;
