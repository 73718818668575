import "./Button.scss";

import classNames from "classnames";
import React from "react";

const Button = ({
  buttonType,
  className,
  message,
  handleOnClick,
  disabled,
  children,
  type = "button",
  ...props
}) => {
  return (
    <button
      className={classNames(
        "Button",
        {
          [`Button--${buttonType}`]: buttonType
        },
        className
      )}
      onClick={handleOnClick}
      disabled={disabled}
      type={type}
      {...props}
    >
      {children || message}
    </button>
  );
};

export default Button;
