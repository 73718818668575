import "./PropertyInfo.scss";
import "./PropertySection.scss";

import ClipboardJS from "clipboard";
import get from "lodash/get";
import React, { Component } from "react";

import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg";
import { AppContext } from "../data/AppContextContainer";
import { PropertyMapLink } from "../scenes/thread/components/PropertyMapLink";
import { AIRBASE_PROPERTY_URL } from "../utils/constants";
import { PROPERTY_INFO_DATA, SECTION_TITLES } from "../utils/propertyInfoData";
import Button from "./Button";
import ExpandableArea from "./ExpandableArea";
import PropertySection from "./PropertySection";

const KeynestInfo = ({ location, code }) => {
  if (!location || !code) {
    return null;
  }

  return (
    <div className="PropertyInfo__container PropertyInfo__container--internal">
      <p className="PropertyInfo__subtitle">Keynest information:</p>

      <div className="PropertyInfo__item">
        <p>
          <strong>Collection code: </strong>
          {code}
        </p>

        <Button
          message={<CopyIcon />}
          buttonType="link"
          data-clipboard-text={`Keynest colection code: ${code}`}
        />
      </div>

      <div className="PropertyInfo__item PropertyInfo__item--address">
        <p>
          <strong>Collection address: </strong>
          {location.name}, {location.street_address}
        </p>

        <PropertyMapLink lat={location.lat} lng={location.lng} />

        <Button
          message={<CopyIcon />}
          buttonType="link"
          data-clipboard-text={`${location.name}, ${location.street_address}`}
        />
      </div>
    </div>
  );
};

export default class PropertyInfo extends Component {
  static contextType = AppContext;

  componentDidMount() {
    this.clipboard = new ClipboardJS("button[data-clipboard-text]");

    this.clipboard.on("success", e => {
      this.context.showNotification({
        title: "Success!",
        message: "Copied to clipboard"
      });

      e.clearSelection();
    });

    this.clipboard.on("error", () => {
      this.context.showNotification({
        title: "Oops!",
        message: "Failed to copy",
        type: "negative"
      });
    });
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  renderSimpleDetails = (property, sectionName) => {
    return PROPERTY_INFO_DATA[sectionName].map((section, index) => {
      const details = get(property, `${section.key}`, null);

      if (!details) {
        return null;
      }

      return (
        <PropertySection key={index} section={section} details={details} />
      );
    });
  };

  propertyInfo = () => {
    const { property } = this.props;
    const { home_info } = property;

    if (!home_info) {
      return null;
    }

    return (
      <div className="PropertyInfo__container PropertyInfo__container--internal">
        <dl className="PropertyInfo__address">
          <dt className="label-info">Address</dt>

          <dd className="PropertyInfo__content">
            {property.full_address}, {property.postcode}
            <Button
              message={<CopyIcon />}
              buttonType="link"
              data-clipboard-text={`${property.full_address}, ${property.postcode}`}
            />
            <PropertyMapLink
              lat={home_info.property_lat}
              lng={home_info.property_lng}
            />
          </dd>
        </dl>

        {this.renderSimpleDetails(property, "propertyInfo")}

        {home_info.access_type === "keynest" && (
          <KeynestInfo
            location={get(home_info, "keynest_key.keystore_location")}
            code={get(home_info, "keynest_key.collection_code")}
          />
        )}
      </div>
    );
  };

  keynestInfo = () => {
    const { property } = this.props;
    const { home_info } = property;

    if (!home_info) {
      return null;
    }

    if (home_info.access_type !== "keynest") {
      return null;
    }

    if (!property.guest_key) {
      return (
        <div className="PropertyInfo__container PropertyInfo__container--internal">
          <p className="PropertyInfo__subtitle text-warning">
            Keynest: missing guest key
          </p>
        </div>
      );
    }

    return (
      <div className="PropertyInfo__container PropertyInfo__container--internal">
        <p className="PropertyInfo__subtitle">Keynest information:</p>

        {property.guest_key.keystore_location ? (
          <div className="PropertyInfo__address">
            <p>
              <strong>Keystore address: </strong>
              {property.guest_key.keystore_location.street_address}
            </p>

            <PropertyMapLink
              lat={property.guest_key.keystore_location.lat}
              lng={property.guest_key.keystore_location.lng}
            />

            <Button
              message={<CopyIcon />}
              buttonType="link"
              data-clipboard-text={`${property.guest_key.keystore_location.street_address}`}
            />
          </div>
        ) : (
          <div className="PropertyInfo__address">
            <p className="text-warning">Keystore info unavailable</p>
          </div>
        )}

        {this.renderSimpleDetails(property, "keynestInfo")}
      </div>
    );
  };

  wifiInfo = () => {
    const { property } = this.props;
    const wifiName = get(property, "home_info.wifi_network_name", null);
    const wifiPassword = get(property, "home_info.wifi_password");

    if (!wifiName || !wifiPassword) {
      return null;
    }

    return (
      <div className="PropertyInfo__container">
        <p className="PropertyInfo__subtitle">WiFi information:</p>

        <div className="PropertyInfo__container--internal">
          <dl className="PropertySection">
            <dt className="label-info">WiFi name</dt>

            <dd className="PropertySection__content">{wifiName}</dd>
          </dl>

          <dl className="PropertySection">
            <dt className="label-info">WiFi password</dt>

            <dd className="PropertySection__content">
              {wifiPassword}
              <Button
                message={<CopyIcon />}
                buttonType="link"
                data-clipboard-text={`${wifiName} (password: ${wifiPassword})`}
              />
            </dd>
          </dl>

          {this.renderSimpleDetails(property, "wifiInfo")}
        </div>
      </div>
    );
  };

  simpleInfo = (title, infoName) => {
    const { property } = this.props;

    return (
      <div className="PropertyInfo__container" key={infoName}>
        <p className="PropertyInfo__subtitle">{title}:</p>
        <div className={`PropertyInfo__container--internal ${title}`}>
          {this.renderSimpleDetails(property, infoName)}
        </div>
      </div>
    );
  };

  printInfo = property => {
    const AIRBASE_URL = `${AIRBASE_PROPERTY_URL}/${property.id}/home_info`;

    return (
      <div className="PropertyInfo__container">
        {this.propertyInfo()}

        {this.keynestInfo()}

        {this.wifiInfo()}

        {Object.keys(SECTION_TITLES).map(sectionKey => {
          return this.simpleInfo(SECTION_TITLES[sectionKey], sectionKey);
        })}

        <a
          className="PropertyInfo__link"
          href={AIRBASE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open full home info in Airbase
        </a>
      </div>
    );
  };

  render() {
    const { property } = this.props;

    return property ? (
      <div className="PropertyInfo" data-testid="property-info">
        <ExpandableArea
          expandableTitle={
            <p className="PropertyInfo__title">
              <span role="img" aria-label="Home icon">
                🏡
              </span>{" "}
              {property.home_code}
            </p>
          }
          expandedContent={this.printInfo(property)}
        />
      </div>
    ) : null;
  }
}
