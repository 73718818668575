import "./ReservationDetails.scss";

import { isAfter } from "date-fns";
import get from "lodash/get";
import React from "react";

import Cancellation from "../../scenes/thread/components/Cancellation";
import { AIRBASE_BOOKINGS_URL, BOOKING_STATUS } from "../../utils/constants";

const ReservationDetails = ({
  reservation,
  mailBoxName,
  booking,
  refreshThread,
  isMultiplat = false
}) => {
  const status = reservation.status;
  const confirmationCode = reservation.confirmation_code;

  const reservationStatus = () => {
    if (!isMultiplat) {
      return status;
    }

    if (booking.approved != null) {
      let context;
      const reviewer = booking.cancellation
        ? "host"
        : get(booking, "reviewer.full_name");

      if (booking.cancellation) {
        context = "cancelled";
      } else {
        context = booking.approved ? "approved" : "rejected";
      }

      return [context, reviewer].join(" by ");
    }

    return "pending";
  };

  const canCancel = () => {
    return (
      confirmationCode &&
      [BOOKING_STATUS.ACCEPT, BOOKING_STATUS.ACCEPTED].includes(status) &&
      isAfter(new Date(reservation.checkout_date), new Date())
    );
  };

  const bookingLink = () => {
    if (!confirmationCode) {
      return "N/A";
    }

    if (booking && booking.id) {
      return (
        <a
          href={`${AIRBASE_BOOKINGS_URL}/${booking.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {confirmationCode}
        </a>
      );
    } else {
      return confirmationCode;
    }
  };

  return (
    <dl className="ReservationDetails">
      <dt className="label-info">Reservation</dt>

      <dd className="ReservationDetails__content">
        {bookingLink()}, {reservationStatus()}
        {canCancel() && (
          <Cancellation
            mailBoxName={mailBoxName}
            reservation={reservation}
            refreshThread={refreshThread}
          />
        )}
      </dd>
    </dl>
  );
};

export default ReservationDetails;
