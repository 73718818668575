import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useLocalStorageSuggestedResponseTracker } from "./useLocalStorageSuggestedResponseTracker";
import { securelyParseJson } from "./utils";

export const useLocalStorageDraft = ({
  state: { draftLoaded, cannedMessages, value },
  onDraftLoad,
  onDraftRestore,
  isModified
}) => {
  const { conversationId, threadId } = useParams();

  const id = conversationId
    ? `conversation-id:${conversationId}`
    : `thread-id:${threadId}`;

  const draftKey = `airbox:draft-message:${id}`;
  const {
    setIsSuggestedResponseUsed
  } = useLocalStorageSuggestedResponseTracker();

  // Ask user if he wants to restore the draft and remove it from local storage
  useEffect(() => {
    if (draftLoaded) {
      return;
    }

    const draftMessage = securelyParseJson(
      window.localStorage.getItem(draftKey)
    );

    if (draftMessage) {
      if (
        window.confirm(
          "There is a draft saved for this thread. Would you like to restore it?"
        )
      ) {
        onDraftRestore(draftMessage);
      } else {
        setIsSuggestedResponseUsed(false);
      }
      window.localStorage.removeItem(draftKey);
    }
    onDraftLoad();
  }, [
    onDraftLoad,
    onDraftRestore,
    draftKey,
    draftLoaded,
    setIsSuggestedResponseUsed
  ]);

  // Saves current message to local storage as a draft
  useEffect(() => {
    // Don't modify local storage until user decided if he wants to restore it
    if (!draftLoaded) {
      return;
    }

    // Keep message in local storage if it's custom or if there are any canned messages
    if (isModified || cannedMessages.length > 0) {
      window.localStorage.setItem(draftKey, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(draftKey);
    }
  }, [cannedMessages.length, draftKey, draftLoaded, isModified, value]);
};
