import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useHistory, useParams } from "react-router-dom";

import { getMaintenance } from "../../services/api";
import Maintenance from "../maintenance/Maintenance";
import Title from "./components/Title";

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export const MaintenanceContext = createContext({});
export const useMaintenanceContext = () => useContext(MaintenanceContext);

export const MaintenanceContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [cancellationToken, setCancellationToken] = useState(null);

  const { maintenanceId } = useParams();
  const history = useHistory();

  const cancelPendingRequests = useCallback(
    message => {
      if (cancellationToken) {
        cancellationToken.cancel(message);
        setCancellationToken(null);
      }
    },
    [cancellationToken]
  );

  const refreshMaintenance = () => {
    handleGetMaintenance({ refreshing: true });
  };

  const handleGetMaintenance = useCallback(
    ({ refreshing }) => {
      setCancellationToken(axios.CancelToken.source());

      if (refreshing) {
        setRefreshing(true);
        setError(false);
      } else {
        setLoading(true);
        setRefreshing(false);
        setError(false);
      }

      getMaintenance(maintenanceId, axios.CancelToken.source().token)
        .then(({ data }) => {
          setData(data);
          setLoading(false);
          setError(false);
        })
        .catch(error => {
          if (error?.response?.data?.status_code === 404) {
            history.push("/not-found");
          }

          if (axios.isCancel(error)) {
            console.info(error.message);
          }

          setLoading(false);
          setRefreshing(false);
          setError(error);
        });
    },
    [history, maintenanceId]
  );

  const previousMaintenanceId = usePrevious(maintenanceId);

  useEffect(() => {
    if (maintenanceId !== previousMaintenanceId) {
      cancelPendingRequests("A newer request for maintenance was made.");

      handleGetMaintenance({ refreshing: false });
    }
  }, [
    maintenanceId,
    cancelPendingRequests,
    handleGetMaintenance,
    previousMaintenanceId
  ]);

  useEffect(() => {
    handleGetMaintenance({ refreshing: false });

    return () =>
      cancelPendingRequests(
        "This request was pending after the component unmounted."
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaintenanceContext.Provider
      value={{
        refreshMaintenance,
        loading,
        refreshing,
        error,
        data,
        handleGetMaintenance
      }}
    >
      {children}
    </MaintenanceContext.Provider>
  );
};

const MaintenanceContainer = () => {
  const { maintenanceId } = useParams();

  return (
    <MaintenanceContextProvider>
      <Title maintenanceId={maintenanceId} />
      <Maintenance />
    </MaintenanceContextProvider>
  );
};

export default MaintenanceContainer;
