import React, { Component, createContext } from "react";

export const AppContext = createContext({
  notifications: [],
  user: { name: "", profileImage: "" }
});
const NOTIFICATION_TIMEOUT = 10000; // 10s

export class AppContextContainer extends Component {
  state = {
    notifications: [],
    showNotification: notification => this.showNotification(notification),
    closeNotification: id => this.filterNotifications(id)
  };

  filterNotifications(id) {
    return this.setState({
      notifications: [...this.state.notifications.filter(n => n.id !== id)]
    });
  }

  showNotification(notification) {
    notification.id = Date.now();

    this.setState(
      {
        notifications: [...this.state.notifications, notification]
      },
      () => {
        // Negative notifications require manual dismissal
        if (notification.type === "negative") {
          return;
        }

        setTimeout(() => {
          this.filterNotifications(notification.id);
        }, NOTIFICATION_TIMEOUT);
      }
    );
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
