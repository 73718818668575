import get from "lodash/get";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../../data/AppContextContainer";
import { sendBookingsyncMessage } from "../../services/api";
import { useIsMounted } from "../../utils/useIsMounted";
import MessengerUI from "./MessengerUI";
import withMessenger from "./withMessenger";

const BookingSyncMessenger = ({
  getDevConfirmation,
  guestName,
  handleReset,
  messengerUIProps,
  message,
  update,
  thread
}) => {
  const { showNotification } = useContext(AppContext);
  const { conversationId } = useParams();
  const [sending, setSending] = useState(false);
  const isMounted = useIsMounted();

  const handleSendMessage = () => {
    if (!getDevConfirmation()) {
      return;
    }

    setSending(true);

    sendBookingsyncMessage(conversationId, thread.sender_id, message)
      .then(() => {
        // If this component has unmounted then don't reset the textarea value as this ref no longer exists
        if (isMounted) {
          handleReset();
          update();
        }

        showNotification({
          title: `Message sent successfully to ${guestName}.`
        });
      })
      .catch(error => {
        showNotification({
          title: `Sending message to ${guestName} failed.`,
          message: get(error, "response.data.error", "Please try again."),
          type: "negative"
        });
      })
      .finally(() => {
        if (isMounted) {
          setSending(false);
        }
      });
  };

  return (
    <MessengerUI
      {...messengerUIProps}
      handleSendMessage={handleSendMessage}
      sending={sending}
    />
  );
};

// withMessenger contains all the shared logic
export default withMessenger(BookingSyncMessenger);
