import "./Actions.scss";

import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { patchMaintenance } from "../../../services/api";
import {
  ACTION_APPROVE,
  ACTION_REJECT,
  ACTION_RESOLVE,
  ACTION_STATUS_CHANGES,
  STATUS_ACCEPTED,
  STATUS_PLANNED
} from "../constants";
import { useMaintenanceContext } from "../MaintenanceContainer";

const STATUSES_FOR_ACTIONS = [STATUS_ACCEPTED, STATUS_PLANNED];

export const Actions = () => {
  const { maintenanceId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const { handleGetMaintenance, data } = useMaintenanceContext();
  const currentStatus = data?.maintenance?.status;

  const handleSubmit = e => {
    patchMaintenance(maintenanceId, {
      status: ACTION_STATUS_CHANGES[newStatus]
    })
      .then(res => {
        setShowModal(false);
        handleGetMaintenance({ refreshing: true });
      })
      .catch(err => console.log(err));
  };

  const handleActionChoice = e => {
    setNewStatus(e.target.id);
    setShowModal(true);
  };

  if (STATUSES_FOR_ACTIONS.includes(currentStatus)) {
    return (
      <div className="Actions">
        {showModal && (
          <Modal
            title={`Are you sure you want to ${newStatus} this issue status?`}
          >
            <div className="ModalButtons">
              <Button buttonType="negative" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              <Button buttonType="positive" onClick={handleSubmit}>
                {" "}
                Confirm
              </Button>
            </div>
          </Modal>
        )}

        {currentStatus === STATUS_ACCEPTED && (
          <Button
            buttonType="info"
            id={ACTION_APPROVE}
            onClick={handleActionChoice}
          >
            Approve
          </Button>
        )}

        <Button
          buttonType="danger"
          id={ACTION_REJECT}
          onClick={handleActionChoice}
        >
          Reject
        </Button>

        {currentStatus === STATUS_PLANNED && (
          <Button
            buttonType="success"
            id={ACTION_RESOLVE}
            onClick={handleActionChoice}
          >
            Resolve
          </Button>
        )}
      </div>
    );
  }

  return null;
};
