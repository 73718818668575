import "./HostPreferences.scss";

import React, { Component } from "react";

import { PREFERENCES } from "../utils/propertyInfoData";
import Button from "./Button";
import ExpandableArea from "./ExpandableArea";

export default class HostPreferences extends Component {
  printResponse = response => {
    if (response === null || response === "contact") {
      return <span className="text-warning">Contact me</span>;
    }

    if (response === false) {
      return <span className="text-error">No</span>;
    }

    if (response === "") {
      return <span>None</span>;
    }

    if (response === true || response === "yes") {
      return <span className="text-success">Yes</span>;
    }

    if (response === "use_best_judgement") {
      return <span className="text-success">Use best judgement</span>;
    }

    return response;
  };

  handleClick = () => {
    window.open(this.props.property.dashboard_link);
  };

  printPreferences = () => {
    const { property } = this.props;
    const { lead, dashboard_link } = property;
    const hostPreferences = property.host_preferences;

    const showDashboardLink = dashboard_link && (
      <div className="show-dashboard" data-testid="dashboard-link">
        <Button
          message="Open Host Dashboard"
          buttonType="link"
          onClick={this.handleClick}
        />
      </div>
    );

    if (!hostPreferences) {
      return (
        <React.Fragment>
          <p className="text-error">
            No preferences have been set on this property yet.
          </p>

          {showDashboardLink}
        </React.Fragment>
      );
    }

    return (
      <>
        <div className="HostPreferences__container">
          {PREFERENCES.map(preference => {
            return (
              <dl key={preference.title} className="HostPreferences__section">
                <dt className="label-info">{preference.title}</dt>

                <dd>{this.printResponse(hostPreferences[preference.key])}</dd>
              </dl>
            );
          })}

          <dl className="HostPreferences__section">
            <dt className="label-info">Host's name</dt>

            <dd>
              {lead.first_name} {lead.last_name}
            </dd>
          </dl>

          <dl className="HostPreferences__section">
            <dt className="label-info">Host's timezone</dt>

            <dd>{lead.contact_timezone || "N/A"}</dd>
          </dl>

          <dl className="HostPreferences__section">
            <dt className="label-info">Host's phone number</dt>

            <dd>
              {lead.phones.map((phone, index) => {
                return (
                  <span key={phone.id}>
                    {phone.number}
                    {index > 0 && ", "}
                  </span>
                );
              })}
            </dd>
          </dl>
        </div>

        {showDashboardLink}
      </>
    );
  };

  render() {
    const { property } = this.props;

    if (!property) {
      return null;
    }

    return (
      <div className="HostPreferences" data-testid="host-preferences">
        <ExpandableArea
          expandableTitle={
            <span className="HostPreferences__title">Host preferences</span>
          }
          expandedContent={this.printPreferences(property)}
        />
      </div>
    );
  }
}
