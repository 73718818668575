import React from "react";
import { Link } from "react-router-dom";

import SearchBar from "./SearchBar";

const AppHeader = ({ link, title, noMargin, right }) => {
  return (
    <div className="AppHeader">
      <SearchBar />
      <div className="AppHeader__title">
        <h4>{title}</h4>

        {link && (
          <Link to={link}>
            <span role="img" aria-label="Inbox icon">
              &#128229;
            </span>{" "}
            View inbox
          </Link>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
