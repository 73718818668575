import "./Notifications.scss";

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CSSTransitionGroup } from "react-transition-group";

import { AppContext } from "../data/AppContextContainer";

export default class Notifications extends Component {
  static contextType = AppContext;

  showLink(notification) {
    if (!notification.link) {
      return false;
    }

    return window.location.pathname !== notification.link;
  }

  render() {
    const { notifications, closeNotification } = this.context;

    return (
      <CSSTransitionGroup
        transitionName="animate-ease"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {notifications.map(notification => {
          return (
            <div
              key={notification.id}
              className={`Notification Notification--${
                notification.type ? notification.type : "positive"
              }`}
            >
              <span
                className="Notification__close"
                onClick={() => closeNotification(notification.id)}
              >
                <svg viewBox="0 0 16 16">
                  <line x1="1" y1="11" x2="11" y2="1" strokeWidth="2" />
                  <line x1="1" y1="1" x2="11" y2="11" strokeWidth="2" />
                </svg>
              </span>

              <p>{notification.title}</p>

              {notification.message && <p>{notification.message}</p>}

              {this.showLink(notification) && (
                <Link
                  onClick={() => closeNotification(notification.id)}
                  to={notification.link}
                >
                  View thread
                </Link>
              )}
            </div>
          );
        })}
      </CSSTransitionGroup>
    );
  }
}
