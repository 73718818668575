import "./GuestImage.scss";

import React from "react";

const GuestImage = ({ url, size = "", status }) => {
  const guestImage = url || `${process.env.PUBLIC_URL}/images/profile.png`;

  return (
    <img
      src={guestImage}
      className={`GuestImage GuestImage--${size} ${status}`}
      alt=""
      data-testid="guest-image"
    />
  );
};

export default GuestImage;
