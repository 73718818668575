import "./GuestFlag.scss";

import React, { Component } from "react";

import { ReactComponent as FlagIcon } from "../../../assets/icons/flag.svg";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import GuestFlagForm from "./GuestFlagForm";
import GuestFlagRemove from "./GuestFlagRemove";

export default class GuestFlag extends Component {
  state = {
    showModal: false
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  renderGuestFlagModal = () => {
    const { guest, refreshThread } = this.props;
    const title = guest.flag
      ? "Remove flag"
      : `Flag guest: ${guest.first_name}`;

    return (
      <Modal title={title} close={this.toggleModal}>
        {guest.flag ? (
          <GuestFlagRemove
            closeModal={this.toggleModal}
            guest={guest}
            refreshThread={refreshThread}
          />
        ) : (
          <GuestFlagForm
            closeModal={this.toggleModal}
            guest={guest}
            refreshThread={refreshThread}
          />
        )}
      </Modal>
    );
  };

  render() {
    const { guest } = this.props;
    const { showModal } = this.state;

    return (
      <React.Fragment>
        {showModal && this.renderGuestFlagModal()}

        <Button
          onClick={this.toggleModal}
          className="GuestFlag__button"
          buttonType="link"
        >
          <FlagIcon />
          {guest.flag ? "Remove flag" : "Flag guest"}
        </Button>
      </React.Fragment>
    );
  }
}
