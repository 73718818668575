import "./PropertySection.scss";

import React from "react";

import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg";
import Button from "./Button";

const PropertySection = ({ section, details }) => (
  <dl className="PropertySection">
    <dt className="label-info">{section.title}</dt>

    <dd className="PropertySection__content">
      {details}
      {section.copy && (
        <Button
          message={<CopyIcon />}
          buttonType="link"
          className="PropertySection__copy"
          data-clipboard-text={details}
        />
      )}
    </dd>
  </dl>
);
export default PropertySection;
