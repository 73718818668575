import React from "react";

import { getFormattedDatetime } from "../../utils/format";

export const Dates = ({ checkin, checkout, checkinTime, checkoutTime }) => {
  return (
    <>
      {checkin && (
        <dl>
          <dt className="label-info">Check-in</dt>
          <dd className="BookingDetails__dates" data-testid="checkin">
            {getFormattedDatetime(checkin, checkinTime)}
          </dd>
        </dl>
      )}

      {checkout && (
        <dl>
          <dt className="label-info">Check-out</dt>
          <dd className="BookingDetails__dates" data-testid="checkout">
            {getFormattedDatetime(checkout, checkoutTime)}
          </dd>
        </dl>
      )}
    </>
  );
};
