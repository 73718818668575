import "./Alteration.scss";

import get from "lodash/get";
import React, { Component } from "react";

import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import { AppContext } from "../../../data/AppContextContainer";
import {
  acceptAlteration,
  declineAlteration,
  withdrawAlteration
} from "../../../services/api";
import { getPendingRequestStatus } from "../../../utils/statusInfo";
import AlterationDetails from "./AlterationDetails";

export default class Alteration extends Component {
  static contextType = AppContext;

  state = {
    loading: false
  };

  handleAcceptAlteration = alterationId => {
    const { mailBoxName, thread, refreshThread } = this.props;

    this.setState({
      loading: true
    });

    acceptAlteration(mailBoxName, alterationId, thread.id)
      .then(() => {
        refreshThread();
        this.context.showNotification({
          title: "Alteration accepted successfully.",
          link: `/mailbox/${mailBoxName}/thread/${thread.id}`
        });
      })
      .catch(error => {
        this.context.showNotification({
          title: "Failed to accept alteration.",
          message: get(
            error,
            "response.data.error",
            "Please refresh the app and try again."
          ),
          type: "negative",
          link: `/mailbox/${mailBoxName}/thread/${thread.id}`
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  handleDeclineAlteration = alterationId => {
    const { mailBoxName, thread, refreshThread } = this.props;

    this.setState({
      loading: true
    });

    declineAlteration(mailBoxName, alterationId, thread.id)
      .then(() => {
        refreshThread();
        this.context.showNotification({
          title: "Alteration declined successfully.",
          link: `/mailbox/${mailBoxName}/thread/${thread.id}`
        });
      })
      .catch(error => {
        this.context.showNotification({
          title: "Failed to decline alteration.",
          message: get(
            error,
            "response.data.error",
            "Please refresh the app and try again."
          ),
          type: "negative",
          link: `/mailbox/${mailBoxName}/thread/${thread.id}`
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  handleWithdrawAlteration = alterationId => {
    const { mailBoxName, thread, refreshThread } = this.props;

    this.setState({
      loading: true
    });

    withdrawAlteration(mailBoxName, alterationId, thread.id)
      .then(() => {
        refreshThread();
        this.context.showNotification({
          title: "Alteration withdrew successfully.",
          link: `/mailbox/${mailBoxName}/thread/${thread.id}`
        });
      })
      .catch(error => {
        this.context.showNotification({
          title: "Failed to withraw alteration.",
          message: get(
            error,
            "response.data.error",
            "Please refresh the app and try again."
          ),
          type: "negative",
          link: `/mailbox/${mailBoxName}/thread/${thread.id}`
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    const { thread, reservation } = this.props;
    const { loading } = this.state;
    const alterationId = get(reservation, "alteration.alteration_id");

    if (!alterationId) {
      return null;
    }

    if (loading) {
      return <Loading message={`Sending request...`} />;
    }

    const pendingRequestStatus = getPendingRequestStatus(thread);

    return (
      <div className="Alteration">
        {pendingRequestStatus === null ? (
          <>
            <AlterationDetails />

            {reservation.alteration.initiated_by === "host" ? (
              <div className="Alteration__info">
                <span>
                  Alteration request sent to guest. Waiting for guest to accept.
                </span>
                <Button
                  buttonType="negative"
                  handleOnClick={() =>
                    this.handleWithdrawAlteration(alterationId)
                  }
                  message="Withdraw alteration"
                />
              </div>
            ) : (
              <div className="Alteration__buttons">
                <Button
                  buttonType="positive"
                  handleOnClick={() =>
                    this.handleAcceptAlteration(alterationId)
                  }
                  message="Accept alteration"
                  disabled={false}
                />
                <Button
                  buttonType="negative"
                  handleOnClick={() =>
                    this.handleDeclineAlteration(alterationId)
                  }
                  message="Decline"
                  disabled={false}
                />
              </div>
            )}
          </>
        ) : (
          `${pendingRequestStatus} request sent to Airbnb`
        )}
      </div>
    );
  }
}
