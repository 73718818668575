import "./styles/index.scss";

import React from "react";
import ReactDOM from "react-dom";

import App from "./scenes/app/App";
import { unregister } from "./serviceWorkerRegistration";

ReactDOM.render(<App />, document.getElementById("root"));
unregister();
