export const PROPERTY_INFO_DATA = {
  propertyInfo: [
    {
      title: "Floor",
      key: "home_info.unit_floor",
      copy: false
    },
    {
      title: "Accommodates",
      key: "accommodates",
      copy: false
    },
    {
      title: "Digital lock code",
      key: "home_info.digital_lock_code",
      copy: true
    },
    {
      title: "Lockbox code",
      key: "home_info.lockbox_code",
      copy: true
    },
    {
      title: "Access type",
      key: "home_info.access_type",
      copy: true
    }
  ],
  keynestInfo: [
    {
      title: "Keystore name",
      key: "guest_key.keystore_location.name",
      copy: false
    },
    {
      title: "Guest key",
      key: "guest_key.name",
      copy: false
    },
    {
      title: "Collection code",
      key: "guest_key.collection_code",
      copy: true
    },
    {
      title: "Status",
      key: "guest_key.status",
      copy: false
    }
  ],
  wifiInfo: [
    {
      title: "WiFi location",
      key: "home_info.wifi_router_location",
      copy: true
    }
  ],
  arrivalInfo: [
    {
      title: "Earliest checkin time",
      key: "home_info.earliest_checkin_time",
      copy: true
    },
    {
      title: "Latest checkout time",
      key: "home_info.latest_checkout_time",
      copy: true
    }
  ],
  maintenanceInfo: [
    {
      title: "Parking notes",
      key: "home_info.parking_notes",
      copy: true
    },
    {
      title: "Heating notes",
      key: "home_info.heating_notes",
      copy: true
    },
    {
      title: "Air conditioning notes",
      key: "home_info.air_conditioning_notes",
      copy: true
    },
    {
      title: "Hot water notes",
      key: "home_info.hot_water_notes",
      copy: true
    },
    {
      title: "Washing machine notes",
      key: "home_info.washing_machine_notes",
      copy: true
    },
    {
      title: "TV instructions",
      key: "home_info.tv_instructions",
      copy: true
    }
  ],
  areaInfo: [
    {
      title: "Nearest station",
      key: "home_info.nearest_station",
      copy: true
    },
    {
      title: "Nearest supermarket",
      key: "home_info.nearest_supermarket",
      copy: true
    }
  ],
  otherInfo: [
    {
      title: "Account manager",
      key: "account_manager.name",
      copy: true
    },
    {
      title: "Salesforce email",
      key: "account_manager.external_email",
      copy: true
    },
    {
      title: "Noise level",
      key: "home_info.unit_noise_level",
      copy: false
    },
    {
      title: "Noise level notes",
      key: "home_info.unit_noise_level_notes",
      copy: true
    }
  ]
};

export const SECTION_TITLES = {
  arrivalInfo: "Arrival information",
  areaInfo: "Area information",
  maintenanceInfo: "Maintenance information",
  otherInfo: "Other"
};

export const PREFERENCES = [
  { title: "Accept children", key: "allow_children" },
  { title: "Accept pets", key: "allow_pets" },
  { title: "Accept 30+ nights bookings", key: "accept_long_bookings" },
  { title: "Special price requests", key: "special_price" }
];
