import "./ConversationFooter.scss";

import React from "react";

import { GOOGLE_TRANSLATE_URL } from "../utils/constants";
import { renderCreatedAtDateTime } from "../utils/datetime";

const ConversationFooter = ({ datetime, operator, message }) => {
  const displayOperator = () => {
    const sentBy =
      operator.split(",").length === 1 ? (
        operator
      ) : (
        <span title={operator}>ambiguous</span>
      );

    return <span> by {sentBy}</span>;
  };

  return (
    <div className="ConversationFooter">
      <small>
        Sent {renderCreatedAtDateTime(datetime)}
        {operator && displayOperator()}
      </small>

      <a
        href={`${GOOGLE_TRANSLATE_URL}${message}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <small>Translate message</small>
      </a>
    </div>
  );
};

export default ConversationFooter;
