import "./ThreadBookingDetails.scss";

import React from "react";
import { useParams } from "react-router-dom";

import Loading from "../components/Loading";
import AlterationRequest from "../scenes/thread/components/AlterationRequest";
import UpdateReservationTimes from "../scenes/thread/components/UpdateReservationTimes";
import { useThreadContext } from "../scenes/thread/ThreadContext";
import { BookingTitle } from "./booking_details/BookingTitle";
import CancellationPolicy from "./booking_details/CancellationPolicy";
import { Dates } from "./booking_details/Dates";
import { EarlyCheckinOption } from "./booking_details/EarlyCheckinOption";
import { LengthOfStay } from "./booking_details/LengthOfStay";
import { NumberOfGuests } from "./booking_details/NumberOfGuests";
import PricingDetails from "./booking_details/PricingDetails";
import { PropertyStatusAlerts } from "./booking_details/PropertyStatusAlerts";
import ReservationDetails from "./booking_details/ReservationDetails";

const ThreadBookingDetails = () => {
  const {
    airbnbThreadInfo,
    propertyInfo,
    refreshThread,
    reservation,
    pricing
  } = useThreadContext();
  const { mailBoxName } = useParams();

  const { property, booking } = propertyInfo;
  const { checkin_date } = airbnbThreadInfo;

  const checkin = booking?.checkin || reservation?.checkin_date;
  const checkout = booking?.checkout || reservation?.checkout_date;

  return (
    <div className="BookingDetails" data-testid="booking-details">
      <BookingTitle
        property={property}
        isMultiplat={false}
        booking={booking}
        thread={airbnbThreadInfo}
      />

      <PropertyStatusAlerts property={property} checkin_date={checkin_date} />

      {booking || reservation ? (
        <>
          {" "}
          <div className="BookingDetails__stay">
            <NumberOfGuests
              property={property}
              booking={booking}
              thread={airbnbThreadInfo}
              reservation={reservation}
            />

            <LengthOfStay
              property={property}
              checkin={checkin}
              checkout={checkout}
            />

            {pricing && <PricingDetails pricing={pricing} />}

            <Dates
              checkin={checkin}
              checkout={checkout}
              checkinTime={booking?.checkin_time}
              checkoutTime={booking?.checkout_time}
            />

            <CancellationPolicy reservation={reservation} />

            {reservation && (
              <ReservationDetails
                reservation={reservation}
                mailBoxName={mailBoxName}
                booking={booking}
                refreshThread={refreshThread}
              />
            )}

            <EarlyCheckinOption booking={booking} />
          </div>
          <div className="BookingDetails__dates">
            <span className="BookingDetails__dates--update">
              <UpdateReservationTimes
                booking={booking}
                refreshThread={refreshThread}
              />
            </span>

            <AlterationRequest
              reservation={reservation}
              thread={airbnbThreadInfo}
              mailBoxName={mailBoxName}
              refreshThread={refreshThread}
            />
          </div>
        </>
      ) : (
        <Loading message={`Loading reservation details...`} />
      )}
    </div>
  );
};

export default ThreadBookingDetails;
