import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useParams } from "react-router-dom";

import {
  getAirbnbInfo,
  getPricing,
  getPropertyInfo,
  getReservation,
  getSentiment
} from "../../services/api";
import { usePrevious } from "../../utils/usePrevious";

export const ThreadContext = createContext({});
export const useThreadContext = () => useContext(ThreadContext);

export const ThreadContextProvider = ({ children }) => {
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [airbnbThreadInfo, setAirbnbThreadInfo] = useState(null);
  const [property, setProperty] = useState(null);
  const [booking, setBooking] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [pricing, setPricing] = useState(null);
  const [guestSentiment, setGuestSentiment] = useState(null);

  const [refreshing, setRefreshing] = useState(true);
  const [error, setError] = useState(false);
  const [propertyInfoLoading, setPropertyInfoLoading] = useState(true);
  const [airbnbThreadInfoLoading, setAirbnbThreadInfoLoading] = useState(true);

  const [cancellationTokens, setCancellationTokens] = useState({
    airbnbThreadInfo: null,
    propertyInfo: null,
    reservation: null,
    pricing: null
  });

  const { mailBoxName, threadId, homeCode } = useParams();

  const cancelPendingRequests = useCallback(
    message => {
      if (cancellationTokens.airbnbThreadInfo) {
        cancellationTokens.airbnbThreadInfo.cancel(message);
        setCancellationTokens({
          ...cancellationTokens,
          airbnbThreadInfo: null
        });

        if (cancellationTokens.propertyInfo) {
          cancellationTokens.propertyInfo.cancel(message);
          setCancellationTokens({
            ...cancellationTokens,
            propertyInfo: null
          });
        } else {
          if (cancellationTokens.reservation) {
            cancellationTokens.reservation.cancel(message);
            setCancellationTokens({
              ...cancellationTokens,
              reservation: null
            });
          }

          if (cancellationTokens.pricing) {
            cancellationTokens.pricing.cancel(message);
            setCancellationTokens({
              ...cancellationTokens,
              pricing: null
            });
          }
        }
      }
    },
    [cancellationTokens]
  );

  const handleGetPropertyInfo = useCallback(
    ({ refreshing }) => {
      setCancellationTokens({
        ...cancellationTokens,
        propertyInfo: axios.CancelToken.source()
      });
      setError(false);

      if (refreshing) {
        setRefreshing(true);
      } else {
        setPropertyInfoLoading(true);
        setRefreshing(false);
      }

      getPropertyInfo(
        mailBoxName,
        threadId,
        homeCode,
        axios.CancelToken.source().token
      )
        .then(({ data }) => {
          setPropertyInfo(data);
          setProperty(data.property);
          setBooking(data.booking);
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.info(error.message);
          }

          setError(error);
        })
        .finally(() => {
          setPropertyInfoLoading(false);
          setRefreshing(false);
        });
    },
    [cancellationTokens, mailBoxName, threadId, homeCode]
  );

  const handleGetGuestSentiment = guestId => {
    getSentiment(guestId)
      .then(response => {
        if (response?.data?.sentiment_score === -1) {
          const newSentiment = {
            ...response.data,
            sentiment_score: "No reviews"
          };
          setGuestSentiment(newSentiment);
        } else {
          setGuestSentiment(response?.data);
        }
      })
      .catch(error => {
        setGuestSentiment({ error: "An error has occurred" });
      });
  };

  const handleGetAirbnbInfo = useCallback(
    ({ refreshing }) => {
      setCancellationTokens({
        ...cancellationTokens,
        airbnbThreadInfo: axios.CancelToken.source()
      });
      setError(false);

      if (refreshing) {
        setRefreshing(true);
      } else {
        setAirbnbThreadInfoLoading(true);
        setRefreshing(false);
      }

      getAirbnbInfo(mailBoxName, threadId, axios.CancelToken.source().token)
        .then(({ data }) => {
          setAirbnbThreadInfo(data.thread);
          setReservation(data.thread.reservation);
          handleGetGuestSentiment(data.thread.guest.id);
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.info(error.message);
          }
          setError(error);
        })
        .finally(() => {
          setAirbnbThreadInfoLoading(false);
          setRefreshing(false);
        });
    },
    [cancellationTokens, mailBoxName, threadId]
  );

  const previousThreadId = usePrevious(threadId);

  useEffect(() => {
    if (threadId !== previousThreadId) {
      cancelPendingRequests("A newer request for a thread was made.");

      handleGetPropertyInfo({ refreshing: false });
      handleGetAirbnbInfo({ refreshing: false });
    }

    return () =>
      cancelPendingRequests(
        "This request was pending after the component unmounted."
      );
  }, [
    threadId,
    cancelPendingRequests,
    handleGetAirbnbInfo,
    handleGetPropertyInfo,
    previousThreadId
  ]);

  useEffect(() => {
    const reservationConfirmationCode =
      airbnbThreadInfo?.reservation?.confirmation_code;

    if (reservationConfirmationCode) {
      getReservation(
        mailBoxName,
        reservationConfirmationCode,
        threadId,
        cancellationTokens.reservation
      ).then(({ data }) => {
        setReservation(data.reservation);
      });
    }
  }, [mailBoxName, airbnbThreadInfo, threadId, cancellationTokens]);

  useEffect(() => {
    getPricing(mailBoxName, threadId, cancellationTokens.pricing).then(
      ({ data }) => {
        setPricing(data.pricing);
      }
    );
  }, [mailBoxName, threadId, cancellationTokens, airbnbThreadInfo]);

  const refreshThread = () => {
    handleGetAirbnbInfo({ refreshing: true });
    handleGetPropertyInfo({ refreshing: true });
  };

  return (
    <ThreadContext.Provider
      value={{
        refreshing,
        error,
        propertyInfo,
        airbnbThreadInfo,
        propertyInfoLoading,
        airbnbThreadInfoLoading,
        property,
        booking,
        reservation,
        pricing,
        refreshThread,
        guestSentiment
      }}
    >
      {children}
    </ThreadContext.Provider>
  );
};
