import { isStaging } from "../services/api";
import { isRunningInFront } from "../services/front";
import { FRONT_LIB_URL, GOOGLE_AUTH_LIB_URL } from "./constants";

const loadScript = scriptURL => {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.async = true;
    script.src = scriptURL;
    script.onload = () => resolve();
    script.onerror = () => reject();
    document.getElementsByTagName("head")[0].appendChild(script);
  });
};

export const loadEnvironment = () => {
  return new Promise((resolve, reject) => {
    if (isRunningInFront) {
      loadScript(FRONT_LIB_URL)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject("Problem loading Front App library, please refresh.");
        });
    } else {
      loadScript(GOOGLE_AUTH_LIB_URL)
        .then(() => {
          window.gapi.load("auth2", () => {
            window.gapi.auth2
              .init({
                client_id: isStaging()
                  ? process.env.REACT_APP_GOOGLE_CLIENT_ID_STAGING
                  : process.env.REACT_APP_GOOGLE_CLIENT_ID,
                fetch_basic_profile: true
              })
              .then(() => {
                resolve();
              });
          });
        })
        .catch(() => {
          reject(
            "Problem loading Google Authentication library, please refresh."
          );
        });
    }
  });
};
