import get from "lodash/get";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../../data/AppContextContainer";
import { useThreadContext } from "../../scenes/thread/ThreadContext";
import { sendAirbnbMessage } from "../../services/api";
import { useIsMounted } from "../../utils/useIsMounted";
import MessengerUI from "./MessengerUI";
import withMessenger from "./withMessenger";

const AirbnbMessenger = ({
  getDevConfirmation,
  getAiGeneratedMessage,
  guestName,
  handleReset,
  messengerUIProps,
  message
}) => {
  const { showNotification } = useContext(AppContext);
  const { refreshThread } = useThreadContext();
  const { mailBoxName, threadId } = useParams();
  const [sending, setSending] = useState(false);
  const isMounted = useIsMounted();

  const handleSendMessage = () => {
    if (!getDevConfirmation()) {
      return;
    }

    const mailboxLink = `/mailbox/${mailBoxName}/thread/${threadId}`;

    setSending(true);

    sendAirbnbMessage(mailBoxName, threadId, message, getAiGeneratedMessage())
      .then(() => {
        // If this component has unmounted then don't reset the textarea value as this ref no longer exists
        if (isMounted) {
          handleReset();
          refreshThread?.();
        }

        showNotification({
          title: `Message sent successfully to ${guestName}.`,
          link: mailboxLink
        });
      })
      .catch(error => {
        showNotification({
          title: `Sending message to ${guestName} failed.`,
          message: get(error, "response.data.error", "Please try again."),
          type: "negative",
          link: mailboxLink
        });
      })
      .finally(() => {
        if (isMounted) {
          setSending(false);
        }
      });
  };

  return (
    <MessengerUI
      {...messengerUIProps}
      handleSendMessage={handleSendMessage}
      sending={sending}
    />
  );
};

// withMessenger contains all the shared logic
export default withMessenger(AirbnbMessenger);
