import "./PricingDetails.scss";

import React from "react";

const PricingDetails = ({ pricing }) => {
  const { host_income } = pricing;

  const pricingMessage = host_income || "Unknown";

  return (
    <dl className="PricingDetails">
      <dt className="label-info">Airbnb payout</dt>

      <dd className="BookingDetails__info" data-testid="pricing-info">
        {pricingMessage}
      </dd>
    </dl>
  );
};

export default PricingDetails;
