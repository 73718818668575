import React from "react";

import { useMaintenanceContext } from "../MaintenanceContainer";

const AIRBASE_URL = "https://airbase.houst.com/maintenance/";
const Title = ({ maintenanceId }) => {
  const { data } = useMaintenanceContext();
  const title =
    data?.maintenance?.summary || `Maintenance issue #${maintenanceId}`;

  return (
    <div className="Maintenance__title">
      <p>{title}</p>
      <a
        href={`${AIRBASE_URL}${maintenanceId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        View in Airbase
      </a>
    </div>
  );
};

export default Title;
