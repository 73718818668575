import "./BookingOrPropertyNotes.scss";

import Linkify from "linkifyjs/react";
import React, { Component } from "react";

import { AIRBASE_BOOKINGS_URL, AIRBASE_PROPERTY_URL } from "../utils/constants";
import { renderCreatedAtDateTime } from "../utils/format";
import ExpandableArea from "./ExpandableArea";

const NOTES_CONFIG = {
  booking: {
    url: AIRBASE_BOOKINGS_URL
  },
  property: {
    url: AIRBASE_PROPERTY_URL
  }
};

export default class BookingOrPropertyNotes extends Component {
  renderNotesSection = notes => {
    return notes.map(note => (
      <div className="BookingOrPropertyNotes__note" key={note.id}>
        <p>
          <Linkify options={{ className: null }}>{note.text}</Linkify>
        </p>

        <small>
          {note.updater
            ? `Updated by ${note.updater.email} `
            : `Added by ${note.operator.email} `}
          {renderCreatedAtDateTime(note.updated_at)}
        </small>
      </div>
    ));
  };

  renderNotesContainer = ({ id, notes, type }) => {
    const linkToAirbaseNotes = `${NOTES_CONFIG[type].url}/${id}`;
    const linkToEditAirbaseNotes = `${linkToAirbaseNotes}/comments/new`;

    return (
      <div
        className="BookingOrPropertyNotes__container"
        data-testid="notes-content"
      >
        <div className="BookingOrPropertyNotes__link-wrapper">
          <a
            href={linkToEditAirbaseNotes}
            className="BookingOrPropertyNotes__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Add new note
          </a>

          <a
            href={linkToAirbaseNotes}
            className="BookingOrPropertyNotes__link BookingOrPropertyNotes__link--delete"
            target="_blank"
            rel="noopener noreferrer"
          >
            Edit or delete notes
          </a>
        </div>

        {this.renderNotesSection(notes)}
      </div>
    );
  };

  render() {
    const { bookingOrProperty, type } = this.props;

    if (!bookingOrProperty?.comments) {
      return null;
    }

    return (
      <div className="BookingOrPropertyNotes">
        <ExpandableArea
          expandableTitle={
            <span className="BookingOrPropertyNotes__title">{`${type} notes`}</span>
          }
          expandedContent={this.renderNotesContainer({
            id: bookingOrProperty.id,
            notes: bookingOrProperty.comments,
            type
          })}
        />
      </div>
    );
  }
}
