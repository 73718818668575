import "./BookingStatus.scss";

import React from "react";

import Alert from "../../../components/Alert";
import Loading from "../../../components/Loading";
import {
  BOOKING_STATUS,
  BOOKING_STATUS_MESSAGES
} from "../../../utils/constants";
import { useThreadContext } from "../ThreadContext";
import BookingActions from "./BookingActions";

const {
  ACCEPT,
  ACTIVE,
  AT_CHECKPOINT,
  AWAITING_PAYMENT,
  CANCELLED,
  CANCELLED_BY_ADMIN,
  CANCELLED_BY_GUEST,
  CANCELLED_BY_HOST,
  DECLINED,
  DENIED,
  INQUIRY,
  NOT_POSSIBLE,
  PENDING,
  PREAPPROVED,
  SPECIAL_OFFER,
  TIMEDOUT
} = BOOKING_STATUS;
const SIMPLE_STATUSES = [
  AWAITING_PAYMENT,
  CANCELLED,
  CANCELLED_BY_ADMIN,
  CANCELLED_BY_GUEST,
  CANCELLED_BY_HOST,
  DECLINED,
  DENIED,
  NOT_POSSIBLE
];

const statusStyles = {
  [AWAITING_PAYMENT]: "fail",
  [CANCELLED]: "fail",
  [CANCELLED_BY_ADMIN]: "fail",
  [CANCELLED_BY_GUEST]: "fail",
  [CANCELLED_BY_HOST]: "fail",
  [DENIED]: "fail",
  [DECLINED]: "fail",
  [TIMEDOUT]: "fail",
  [ACTIVE]: "pending",
  [NOT_POSSIBLE]: "pending",
  [SPECIAL_OFFER]: "pending",
  [INQUIRY]: "pending",
  [PENDING]: "pending",
  [AT_CHECKPOINT]: "pending",
  [PREAPPROVED]: "success"
};

const BookingStatus = () => {
  const { airbnbThreadInfo, refreshing } = useThreadContext();
  const status = airbnbThreadInfo?.reservation?.status;

  const renderStatus = () => {
    const { title, message } = BOOKING_STATUS_MESSAGES[status] || {};
    const statusStyle = statusStyles[status];

    return (
      <>
        {Boolean(statusStyle) && (
          <Alert title={title} message={message} status={statusStyle} />
        )}

        {!SIMPLE_STATUSES.includes(status) && (
          // Some statuses require no actions - we just show an alert to the user
          <BookingActions status={status} />
        )}
      </>
    );
  };

  if (status === ACCEPT) {
    return null;
  }

  return (
    <div className="BookingStatus" data-testid="booking-status">
      {refreshing ? (
        <Loading message="Checking for booking changes..." />
      ) : (
        renderStatus()
      )}
    </div>
  );
};

export default BookingStatus;
