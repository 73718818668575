import React from "react";
import { useParams } from "react-router-dom";

import Alert from "../../components/Alert";
import BookingOrPropertyNotes from "../../components/BookingOrPropertyNotes";
import HostPreferences from "../../components/HostPreferences";
import Loading from "../../components/Loading";
import PropertyInfo from "../../components/PropertyInfo";
import { formatPropertyError } from "../../utils/format";
import HostInstructions from "./components/HostInstructions";
import { VerificationTasksList } from "./components/VerificationTasksList";
import { useThreadContext } from "./ThreadContext";

export const Property = () => {
  const {
    error,
    propertyInfo,
    propertyInfoLoading,
    property,
    booking
  } = useThreadContext();
  const { mailBoxName } = useParams();

  if (propertyInfoLoading) {
    return (
      <Loading message={`Loading property information for ${mailBoxName}...`} />
    );
  }

  if (error) {
    const formattedMessage = formatPropertyError(error, mailBoxName);

    return (
      <Alert
        title="Something went wrong..."
        message={formattedMessage}
        status="fail"
        type="block"
      />
    );
  }

  if (!propertyInfo) {
    return null;
  }

  return (
    <>
      <div
        className="instructions-verification-tasks"
        data-testid="property-info"
      >
        <HostInstructions />
        <VerificationTasksList />
      </div>

      <PropertyInfo property={property} />

      <HostPreferences property={property} />

      <BookingOrPropertyNotes bookingOrProperty={booking} type="booking" />

      <BookingOrPropertyNotes bookingOrProperty={property} type="property" />
    </>
  );
};
