import "./AlterationDetails.scss";

import { format } from "date-fns";
import React from "react";

import { DATE_FORMAT } from "../../../utils/constants";
import { useThreadContext } from "../ThreadContext";

const AlterationDetails = () => {
  const { reservation } = useThreadContext();

  const alteration = reservation?.alteration;
  const hostIncome = reservation?.pricing?.host_income;

  if (!reservation || !alteration) {
    return null;
  }

  let messages = [];

  if (reservation.checkin_date !== alteration.checkin_date) {
    messages.push({
      content: (
        <span className="AlterationDetails__message" data-testid="checkin">
          Checkin date changed from{" "}
          <strong>{format(reservation.checkin_date, DATE_FORMAT)}</strong> to{" "}
          <strong>{format(alteration.checkin_date, DATE_FORMAT)}</strong>
        </span>
      ),
      key: "checkin"
    });
  }

  if (reservation.checkout_date !== alteration.checkout_date) {
    messages.push({
      content: (
        <span className="AlterationDetails__message" data-testid="checkout">
          Checkout date changed from{" "}
          <strong>{format(reservation.checkout_date, DATE_FORMAT)}</strong> to{" "}
          <strong>{format(alteration.checkout_date, DATE_FORMAT)}</strong>
        </span>
      ),
      key: "checkout"
    });
  }

  if (reservation.number_of_adults !== alteration.guests_adults) {
    messages.push({
      content: (
        <span className="AlterationDetails__message" data-testid="guests">
          Number of guests changed from{" "}
          <strong>{reservation.number_of_adults}</strong> to{" "}
          <strong>{alteration.guests_adults}</strong>
        </span>
      ),
      key: "number"
    });
  }

  if (hostIncome && hostIncome !== alteration.host_income) {
    messages.push({
      content: (
        <span className="AlterationDetails__message" data-testid="income">
          Airbnb payout will change from{" "}
          <strong>{reservation.pricing.host_income}</strong> to{" "}
          <strong>{alteration.host_income}</strong>
        </span>
      ),
      key: "payout"
    });
  }

  return (
    <div className={"AlterationDetails"}>
      <div className={"AlterationDetails__title"}>
        Pending alteration request:
      </div>
      {messages.map(message => (
        <div key={message.key}>&#8226; {message.content}</div>
      ))}
    </div>
  );
};

export default AlterationDetails;
