import "./GuestFlagForm.scss";

import get from "lodash/get";
import React, { Component } from "react";

import Button from "../../../components/Button";
import { AppContext } from "../../../data/AppContextContainer";
import { removeFlag } from "../../../services/api";

export default class GuestFlagRemove extends Component {
  static contextType = AppContext;

  removeFlag = () => {
    const { guest, closeModal, refreshThread } = this.props;

    removeFlag(guest.flag.id)
      .then(() => {
        closeModal();
        refreshThread();
      })
      .catch(error => {
        closeModal();

        this.context.showNotification({
          title: "Removing guest flag failed.",
          message: get(error, "response.data.error", "Please try again."),
          type: "negative"
        });
      });
  };

  render() {
    const { closeModal } = this.props;

    return (
      <React.Fragment>
        <p>Are you sure you want to remove the flag from this guest?</p>

        <div className="GuestFlagForm__actions">
          <Button
            handleOnClick={closeModal}
            buttonType="negative"
            message="Cancel"
          />

          <Button
            handleOnClick={this.removeFlag}
            type="submit"
            buttonType="positive"
            message="Remove flag"
          />
        </div>
      </React.Fragment>
    );
  }
}
