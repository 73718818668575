import get from "lodash/get";
import React from "react";
import { Tooltip } from "react-tippy";

import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";

export const EarlyCheckinOption = ({ booking }) => {
  if (!booking || !get(booking, "early_checkin.applicable")) {
    return null;
  }

  const earlyCheckinStatus = () => {
    if (booking.early_checkin.status === null) {
      return { label: "warning", message: "Uncertain" };
    } else if (booking.early_checkin.status) {
      return { label: "positive", message: "Possible" };
    }

    return { label: "negative", message: "Not possible" };
  };

  const getEarlyCheckinInfo = data => {
    return (
      <ul>
        {data.map(reason => {
          return <li key={reason.toString()}>{reason}</li>;
        })}
      </ul>
    );
  };

  return (
    <dl data-testid="early-checkin-option">
      <dt className="label-info">Early Checkin</dt>
      <dd className="BookingDetails__early-checkin">
        <span data-testid="early-checkin-status">
          {earlyCheckinStatus().message}
        </span>

        <Tooltip
          className="EarlyCheckinTooltip"
          html={
            <p className="tooltip">
              {getEarlyCheckinInfo(booking.early_checkin.explanation)}
            </p>
          }
          position="bottom"
          trigger="click"
          interactive
        >
          <InfoIcon />
        </Tooltip>
      </dd>
    </dl>
  );
};
