import Alert from "../../../components/Alert";
import { useThreadContext } from "../ThreadContext";

export const VerificationTasksList = () => {
  const { property } = useThreadContext();

  const verificationTasks = property?.home_info?.guest_verification_tasks || [];

  if (!verificationTasks.length) {
    return null;
  }

  return (
    <div className="VerificationTasksList">
      <Alert
        title="Verification tasks"
        message={
          <ul>
            {verificationTasks.map(({ description, id }) => (
              <li key={id}>- {description}</li>
            ))}
          </ul>
        }
      />
    </div>
  );
};
