import "./Footer.scss";

import React from "react";
import { useHistory } from "react-router-dom";

import Button from "../../../components/Button";
import Version from "./Version";

export const Footer = () => {
  const history = useHistory();
  const handleNewMaintenanceClick = () => {
    history.push("/maintenance");
  };

  return (
    <div className="Footer">
      <Button onClick={handleNewMaintenanceClick} buttonType="positive">
        Create new maintenance
      </Button>

      <Version />
    </div>
  );
};
