import "./Search.scss";

import get from "lodash/get";
import React from "react";

import Alert from "../../components/Alert";
import GuestImage from "../../components/GuestImage";
import HostPreferences from "../../components/HostPreferences";
import Loading from "../../components/Loading";
import BookingSyncMessenger from "../../components/messenger/BookingSyncMessenger";
import MultiplatBookingDetails from "../../components/MultiplatBookingDetails";
import PropertyInfo from "../../components/PropertyInfo";
import { formatPropertyError } from "../../utils/format";
import Conversation from "../thread/components/Conversation";
import HostInstructions from "../thread/components/HostInstructions";
import BookingComments from "./components/BookingComments";

const Search = ({ state, update }) => {
  const { loading, error, data, bookingsyncConversation } = state;

  if (loading) {
    return <Loading message={"Loading information for multiplat message..."} />;
  }

  if (error) {
    const formattedMessage = formatPropertyError(
      error,
      get(data, "property.home_code", null)
    );

    return (
      <Alert
        title={"Something went wrong..."}
        message={formattedMessage}
        status="fail"
        type="block"
      />
    );
  }

  const alertMultipleBookingsFound = () => {
    const { bookings } = data;

    return bookings && bookings.length > 1 ? (
      <Alert
        title={"Multiple bookings found for this guest"}
        message={"Please check each booking for more information."}
        status="pending"
        type="block"
      />
    ) : null;
  };

  const guestInfo = booking => {
    const bookingStatus = booking.approved ? "accepted" : "denied";

    return (
      <div className="Search__guest-info">
        <GuestImage
          url={booking.guest_photo_url}
          status={bookingStatus}
          size="large"
        />
        <h4>{booking.guest_name}</h4>
      </div>
    );
  };

  const bookingsInfo = bookings =>
    bookings.map(booking => {
      return (
        <div key={booking.id}>
          <MultiplatBookingDetails
            booking={booking}
            property={booking.property}
          />

          {guestInfo(booking)}

          <BookingComments comments={booking.comments} />
        </div>
      );
    });

  const propertyInfo = property => {
    return (
      <div>
        <HostInstructions property={property} />

        <PropertyInfo property={property} />

        <HostPreferences property={property} />
      </div>
    );
  };

  const conversation = ({ thread }, property, booking) => {
    console.log({ thread, booking });
    return (
      <div>
        <Conversation thread={thread} refreshing={loading} booking={booking} />

        <BookingSyncMessenger
          booking={booking}
          guestName={booking.guest_name}
          property={property}
          thread={thread}
          update={update}
        />
      </div>
    );
  };

  const searchResults = () => {
    const { bookings, property } = data;

    if (!property && !bookings) {
      return null;
    }

    const prop = property || bookings[0].property;

    return (
      <div className="Search__result" data-testid="search">
        {bookings && bookingsInfo(bookings)}
        {prop && propertyInfo(prop)}
        {bookingsyncConversation &&
          conversation(bookingsyncConversation, prop, bookings[0])}
      </div>
    );
  };

  if (data) {
    return (
      <div className="Search">
        {alertMultipleBookingsFound()}

        <div className="Search__results">{searchResults()}</div>
      </div>
    );
  }

  return null;
};

export default Search;
