import "./CancellationPolicy.scss";

import React from "react";
import { Tooltip } from "react-tippy";

import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import {
  CANCELLATION_POLICIES,
  CANCELLATION_POLICIES_FRIENDLY_NAME
} from "../../utils/constants";

const CancellationPolicy = ({ reservation }) => {
  const cancellationPolicy = reservation?.cancellation_policy_category;

  if (!cancellationPolicy) {
    return null;
  }

  return (
    <dl
      title={CANCELLATION_POLICIES[cancellationPolicy]}
      data-testid="cancellation-policy"
    >
      <dt className="label-info">Cancellation policy</dt>
      <dd className="CancellationPolicy">
        {CANCELLATION_POLICIES_FRIENDLY_NAME[cancellationPolicy] ||
          cancellationPolicy}

        <Tooltip
          className="Tooltip"
          html={
            <p className="tooltip">
              {CANCELLATION_POLICIES[cancellationPolicy]}
            </p>
          }
          position="bottom"
          trigger="click"
          interactive
          data-testid="cancellation-tooltip"
        >
          <InfoIcon />
        </Tooltip>
      </dd>
    </dl>
  );
};

export default CancellationPolicy;
