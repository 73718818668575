import React from "react";
import { useParams } from "react-router-dom";

import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import { useThreadContext } from "../../scenes/thread/ThreadContext";
import { formatPropertyError } from "../../utils/format";
import AirbnbMessenger from "./AirbnbMessenger";

export const AirbnbMessengerContainer = () => {
  const {
    airbnbThreadInfo,
    booking,
    error,
    property,
    propertyInfo,
    propertyInfoLoading
  } = useThreadContext();
  const { mailBoxName } = useParams();

  const guestName = airbnbThreadInfo?.guest?.first_name;

  if (propertyInfoLoading) {
    return <Loading message={`Loading messenger for ${mailBoxName}...`} />;
  }

  if (error) {
    const formattedMessage = formatPropertyError(error, mailBoxName);

    return (
      <Alert
        title="Something went wrong while loading messenger..."
        message={formattedMessage}
        status="fail"
        type="block"
      />
    );
  }

  if (!propertyInfo) {
    return null;
  }

  return (
    <AirbnbMessenger
      booking={booking}
      guestName={guestName}
      property={property}
      thread={airbnbThreadInfo}
    />
  );
};
