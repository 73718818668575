import "./ExpandableArea.scss";

import React, { Component } from "react";

import { ReactComponent as CloseIcon } from "../assets/icons/toggle_close.svg";
import { ReactComponent as OpenIcon } from "../assets/icons/toggle_open.svg";

export default class ExpandableArea extends Component {
  state = {
    expanded: false
  };

  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  render() {
    const { expanded } = this.state;
    const {
      expandableTitle,
      expandedContent,
      openLabel,
      closeLabel,
      buttonType
    } = this.props;
    const toggleOpen = openLabel || <OpenIcon />;
    const toggleClose = closeLabel || <CloseIcon />;
    const type = buttonType || "link";

    return (
      <div className="ExpandableContainer">
        <button
          className={`ExpandableArea__${type}`}
          onClick={this.toggleExpanded}
          data-testid="expandable-area"
        >
          {expandableTitle}
          {expanded ? toggleClose : toggleOpen}
        </button>

        {expanded && expandedContent}
      </div>
    );
  }
}
