// --------- LOCATION ---------
const LOCATION_BATHROOM = "location_bathroom";
const LOCATION_BEDROOM = "location_bedroom";
const LOCATION_KITCHEN = "location_kitchen";
const LOCATION_LIVING_ROOM = "location_living_room";
const LOCATION_OUTDOOR = "location_outdoor";
const LOCATION_HALLWAY = "location_hallway";
const LOCATION_OTHER = "location_other";

export const LOCATIONS = [
  LOCATION_BATHROOM,
  LOCATION_BEDROOM,
  LOCATION_KITCHEN,
  LOCATION_LIVING_ROOM,
  LOCATION_OUTDOOR,
  LOCATION_HALLWAY,
  LOCATION_OTHER
];

export const ISSUE_LOCATIONS = {
  [LOCATION_BATHROOM]: "Bathroom",
  [LOCATION_BEDROOM]: "Bedroom",
  [LOCATION_KITCHEN]: "Kitchen",
  [LOCATION_LIVING_ROOM]: "Living room",
  [LOCATION_OUTDOOR]: "Outdoor",
  [LOCATION_HALLWAY]: "Hallway",
  [LOCATION_OTHER]: "Other"
};

export const ISSUE_LOCATIONS_OPTIONS = [
  {
    value: LOCATION_BATHROOM,
    label: "Bathroom"
  },
  {
    value: LOCATION_BEDROOM,
    label: "Bedroom"
  },
  {
    value: LOCATION_KITCHEN,
    label: "Kitchen"
  },
  {
    value: LOCATION_LIVING_ROOM,
    label: "Living room"
  },
  {
    value: LOCATION_OUTDOOR,
    label: "Outdoor"
  },
  {
    value: LOCATION_OTHER,
    label: "Other"
  }
];

// --------- SEVERITY ---------
const SEVERITY_EMERGENCY = "emergency";
const SEVERITY_URGENT = "urgent";
const SEVERITY_NON_URGENT = "non_urgent";

export const SEVERITIES = [
  SEVERITY_EMERGENCY,
  SEVERITY_URGENT,
  SEVERITY_NON_URGENT
];

export const ISSUE_SEVERITY = {
  [SEVERITY_EMERGENCY]: "Emergency",
  [SEVERITY_URGENT]: "Urgent",
  [SEVERITY_NON_URGENT]: "Non urgent"
};

export const ISSUE_SEVERITY_OPTIONS = [
  {
    value: SEVERITY_EMERGENCY,
    label: "Emergency"
  },
  {
    value: SEVERITY_URGENT,
    label: "Urgent"
  },
  {
    value: SEVERITY_NON_URGENT,
    label: "Non urgent"
  }
];

// --------- STATUS ---------
const STATUS_PENDING = "pending";
export const STATUS_ACCEPTED = "accepted";
export const STATUS_PLANNED = "planned";
const STATUS_HOST_PLANNED = "host_planned";
const STATUS_RESOLVED = "resolved";
const STATUS_REJECTED = "rejected";
const STATUS_IGNORED = "ignored";

export const ISSUE_STATUS = {
  [STATUS_PENDING]: "Pending",
  [STATUS_ACCEPTED]: "Accepted",
  [STATUS_PLANNED]: "Planned",
  [STATUS_HOST_PLANNED]: "Host planned",
  [STATUS_RESOLVED]: "Resolved",
  [STATUS_REJECTED]: "Rejected",
  [STATUS_IGNORED]: "Ignored"
};

export const ISSUE_STATUS_OPTIONS = [
  {
    value: STATUS_PENDING,
    label: "Pending"
  },
  {
    value: STATUS_ACCEPTED,
    label: "Accepted"
  },
  {
    value: STATUS_PLANNED,
    label: "Planned"
  },
  {
    value: STATUS_HOST_PLANNED,
    label: "Host planned"
  },
  {
    value: STATUS_RESOLVED,
    label: "Resolved"
  },
  {
    value: STATUS_REJECTED,
    label: "Rejected"
  },
  {
    value: STATUS_IGNORED,
    label: "Ignored"
  }
];

// --------- TYPE ---------
const TYPE_EMERGENCY = "emergency";
const TYPE_DAMAGED = "damaged";
const TYPE_BROKEN_ITEM = "broken_item";
const TYPE_FAULTY_APPLIANCE = "faulty_appliance";
const TYPE_BLOCKAGE = "blockage";
const TYPE_LEAK = "leak";
const TYPE_OTHER = "other";

export const TYPES = [
  TYPE_EMERGENCY,
  TYPE_DAMAGED,
  TYPE_BROKEN_ITEM,
  TYPE_FAULTY_APPLIANCE,
  TYPE_BLOCKAGE,
  TYPE_LEAK,
  TYPE_OTHER
];

export const ISSUE_TYPE = {
  [TYPE_EMERGENCY]: "Emergency",
  [TYPE_DAMAGED]: "Damaged",
  [TYPE_BROKEN_ITEM]: "Broken item",
  [TYPE_FAULTY_APPLIANCE]: "Faulty appliance",
  [TYPE_BLOCKAGE]: "Blockage",
  [TYPE_LEAK]: "Leak",
  [TYPE_OTHER]: "Other"
};

export const ISSUE_TYPE_OPTIONS = [
  {
    value: TYPE_EMERGENCY,
    label: "Emergency"
  },
  {
    value: TYPE_DAMAGED,
    label: "Damaged"
  },
  {
    value: TYPE_BROKEN_ITEM,
    label: "Broken item"
  },
  {
    value: TYPE_FAULTY_APPLIANCE,
    label: "Faulty appliance"
  },
  {
    value: TYPE_BLOCKAGE,
    label: "Blockage"
  },
  {
    value: TYPE_LEAK,
    label: "Leak"
  },
  {
    value: TYPE_OTHER,
    label: "Other"
  }
];

// --------- REPORTER TYPE ---------
const REPORTER_TYPE_HOST_VIA_CS = "host_via_cs";
const REPORTER_TYPE_GUEST_VIA_GEX = "guest_via_gex";
const REPORTER_TYPE_CLEANER = "cleaner";
const REPORTER_TYPE_OPERATOR = "operator";

export const REPORTER_TYPE = {
  [REPORTER_TYPE_HOST_VIA_CS]: "Host via CS",
  [REPORTER_TYPE_GUEST_VIA_GEX]: "Guest via GEX",
  [REPORTER_TYPE_CLEANER]: "Cleaner",
  [REPORTER_TYPE_OPERATOR]: "Operator"
};

// --------- HOST RESPONSE ---------
const HOST_RESPONSE_AIRSORTED_RESOLVE = "airsorted_resolve";
const HOST_RESPONSE_HOST_RESOLVE = "host_resolve";
const HOST_RESPONSE_IGNORE = "ignore";

export const HOST_RESPONSE = {
  [HOST_RESPONSE_AIRSORTED_RESOLVE]: "Houst resolve",
  [HOST_RESPONSE_HOST_RESOLVE]: "Host resolve",
  [HOST_RESPONSE_IGNORE]: "Ignore"
};

// --------- MAINTENANCE HOST PREFERENCES ---------
const MAINTENANCE_TYPE_IMMEDIATELY = "immediately";
const MAINTENANCE_TYPE_WITH_APPROVAL = "with_approval";
const MAINTENANCE_TYPE_HOST_RESOLVE = "host_resolve";

export const MAINTENANCE_PREFERENCES_USER_FRIENDLY = {
  [MAINTENANCE_TYPE_IMMEDIATELY]: "Immediately",
  [MAINTENANCE_TYPE_WITH_APPROVAL]: "With approval",
  [MAINTENANCE_TYPE_HOST_RESOLVE]: "Host resolve"
};

// --------- MAINTENANCE STATUS ACTION CHANGES ---------
export const ACTION_APPROVE = "approve";
export const ACTION_REJECT = "reject";
export const ACTION_RESOLVE = "resolve";

export const ACTION_STATUS_CHANGES = {
  [ACTION_APPROVE]: STATUS_PLANNED,
  [ACTION_REJECT]: STATUS_REJECTED,
  [ACTION_RESOLVE]: STATUS_RESOLVED
};
