import React from "react";

import Alert from "../../components/Alert";
import SearchBar from "../../components/SearchBar";

const NotFound = ({ location }) => {
  const message = location.error
    ? location.error
    : "No booking information could be found for the message selected. Please search on Airbase.";

  return (
    <div className="AppHeader">
      <SearchBar />
      <Alert title="Not Found..." message={message} />
    </div>
  );
};

export default NotFound;
