import get from "lodash/get";
import React from "react";

export const NumberOfGuests = ({ thread, booking, property, reservation }) => {
  const getGuestAccommodationModifier = (accommodates, numberOfGuests) => {
    const ACCEPTABLE_GUESTS_RATIO = 0.34;

    if (!accommodates) {
      return "warning";
    }

    if (numberOfGuests > accommodates) {
      return "warning";
    }

    const ratio = (numberOfGuests / accommodates).toFixed(2);
    const isSuspicious = ratio <= ACCEPTABLE_GUESTS_RATIO;

    return isSuspicious ? "warning" : "success";
  };

  const numberOfGuests =
    reservation?.number_of_guests || booking?.num_guests || null;

  if (!numberOfGuests) {
    return null;
  }

  const accommodates = get(property, "accommodates");

  const guestModifierClass = getGuestAccommodationModifier(
    accommodates,
    numberOfGuests
  );

  const adults = reservation?.number_of_adults;
  const adultsText = adults ? `${adults} adult${adults > 1 ? "s" : ""}` : "";

  const children = reservation?.number_of_children;
  const childrenText = children
    ? `${children} child${children > 1 ? "ren" : ""}`
    : "";

  const infants = reservation?.number_of_infants;
  const infantsText = infants
    ? `${infants} infant${infants > 1 ? "s" : ""}`
    : "";

  const pets = reservation?.number_of_pets;
  const petsText = pets ? `${pets} pet${pets > 1 ? "s" : ""}` : "";

  const guestsBreakdown =
    [adultsText, childrenText, infantsText, petsText]
      .filter(Boolean)
      .join(", ") || "No breakdown available";

  return (
    <dl
      className="BookingDetails__guests"
      title={guestsBreakdown}
      data-testid="number-of-guests"
    >
      <dt className="label-info">Guests</dt>
      <dd className="BookingDetails__info">
        <span
          className={`BookingDetails__guests-number BookingDetails__guests-number--${guestModifierClass}`}
          data-testid={guestModifierClass}
        >
          {numberOfGuests}
        </span>
        <span
          className="BookingDetails__guests-accommodates"
          data-testid="accommodates"
        >
          {" "}
          /{accommodates || "⚠️ No accommodation info in Airbase"}
        </span>
      </dd>
    </dl>
  );
};
