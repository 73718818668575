import "./Alert.scss";

import React from "react";

const Alert = ({ title, message, status, type = "inline", extra }) => {
  return (
    <div
      className={`Alert Alert--${status} Alert--${type}`}
      data-testid="alert-container"
    >
      {title && <p className="Alert__title">{title}</p>}

      {message && <div className="Alert__message">{message}</div>}

      {extra && <small className="Alert__extra">{extra}</small>}
    </div>
  );
};

export default Alert;
