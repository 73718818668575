import get from "lodash/get";
import React, { useEffect, useState } from "react";
import Select from "react-select";

import { getCannedResponses } from "../../services/api";
import { formatCannedMessages, setSelectMenuTheme } from "../../utils/format";
import { useIsMounted } from "../../utils/useIsMounted";

const CannedResponses = ({
  booking,
  cannedMessageValue,
  handleCannedMessageSelection,
  property
}) => {
  const [error, updateError] = useState();
  const isMounted = useIsMounted();

  const [cannedMessages, updateCannedMessages] = useState([]);

  useEffect(() => {
    const localityName = get(property, "locality.name");
    const bookingId = get(booking, "id");
    const propertyId = get(property, "id");

    if (isMounted) {
      getCannedResponses({
        localityName,
        bookingId,
        propertyId
      })
        .then(({ data }) => {
          const formattedCannedMessages = formatCannedMessages(
            data.canned_responses
          );

          updateCannedMessages(formattedCannedMessages);
        })
        .catch(() => {
          updateError("Could not load Canned Responses");
        });
    }
  }, [booking, isMounted, property]);

  if (error) {
    return <span className="text-error">{error}</span>;
  }

  return (
    <Select
      className="Messenger__canned-messages"
      menuPlacement="top"
      placeholder="Canned messages"
      value={cannedMessageValue}
      options={cannedMessages}
      isLoading={!cannedMessages.length}
      onChange={handleCannedMessageSelection}
      blurInputOnSelect={true}
      theme={theme => setSelectMenuTheme(theme)}
      data-testid="canned-messages-select"
    />
  );
};

export default CannedResponses;
