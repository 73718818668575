import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { securelyParseJson } from "./utils";

/*
If they don't use the AI button -> we send 'message'
If they use the AI button –> we send 'message' and 'ai_generated_message'
If they use AI button, refresh the page, use a draft -> we send 'message' and 'ai_generated_message'
If they use AI button, refresh the page, don't use a draft -> we send 'message'

This is not a reducer mainly because users can use drafts.
*/
export const useLocalStorageSuggestedResponseTracker = () => {
  const { conversationId, threadId } = useParams();
  const id = conversationId
    ? `conversation-id:${conversationId}`
    : `thread-id:${threadId}`;

  const storageKey = `airbox:ai-suggested-message-used:${id}`;

  const getIsSuggestedResponseUsed = useCallback(
    () => securelyParseJson(window.localStorage.getItem(storageKey)),
    [storageKey]
  );

  const setIsSuggestedResponseUsed = useCallback(
    value => {
      window.localStorage.setItem(storageKey, JSON.stringify(value));
    },
    [storageKey]
  );

  const resetIsSuggestionsResponseUsed = useCallback(() => {
    window.localStorage.removeItem(storageKey);
  }, [storageKey]);

  return {
    getIsSuggestedResponseUsed,
    setIsSuggestedResponseUsed,
    resetIsSuggestionsResponseUsed
  };
};
