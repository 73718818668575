import "./Images.scss";

import React from "react";

export const Images = ({ images }) => {
  if (!images || (Array.isArray(images) && images.length === 0)) {
    return null;
  }

  return (
    <div className="Images">
      {images.map(image => (
        <div className="Image" key={image.image_url}>
          <a href={image.image_url} target="_blank" rel="noopener noreferrer">
            <img src={image.thumbnail_url} alt="Maintenance" />
          </a>
        </div>
      ))}
    </div>
  );
};
