import "./ThreadBookingDetails.scss";

import React from "react";

import UpdateReservationTimes from "../scenes/thread/components/UpdateReservationTimes";
import { BookingTitle } from "./booking_details/BookingTitle";
import { Dates } from "./booking_details/Dates";
import { EarlyCheckinOption } from "./booking_details/EarlyCheckinOption";
import { LengthOfStay } from "./booking_details/LengthOfStay";
import { NumberOfGuests } from "./booking_details/NumberOfGuests";
import { PropertyStatusAlerts } from "./booking_details/PropertyStatusAlerts";
import ReservationDetails from "./booking_details/ReservationDetails";

const MultiplatBookingDetails = ({
  thread,
  property,
  booking,
  mailBoxName,
  reservation
}) => {
  const checkin = booking?.checkin || thread?.reservation?.checkin_date;
  const checkout = booking?.checkout || thread?.reservation?.checkout_date;

  if (checkin && checkout) {
    return (
      <div className="BookingDetails" data-testid="booking-details">
        <BookingTitle
          property={property}
          isMultiplat={true}
          booking={booking}
          thread={thread}
        />

        <PropertyStatusAlerts property={property} checkin_date={checkin} />

        <div className="BookingDetails__stay">
          <NumberOfGuests
            property={property}
            booking={booking}
            thread={thread}
          />

          <LengthOfStay
            property={property}
            checkin={checkin}
            checkout={checkout}
          />

          <Dates
            checkin={checkin}
            checkout={checkout}
            checkinTime={booking?.checkin_time}
            checkoutTime={booking?.checkout_time}
          />

          <ReservationDetails
            reservation={{ confirmation_code: booking?.platform_ref }}
            mailBoxName={mailBoxName}
            booking={booking}
          />

          <EarlyCheckinOption booking={booking} />
        </div>

        <div className="BookingDetails__dates">
          <span className="BookingDetails__dates--update">
            <UpdateReservationTimes booking={booking} />
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export default MultiplatBookingDetails;
