import "./BookingActionsGuestVerificationTasksForm.scss";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";

import Button from "../../../components/Button";
import { ErrorMessageContainer } from "../../../components/ErrorMessageContainer";
import { useThreadContext } from "../ThreadContext";
import HostInstructions from "./HostInstructions";

const validationSchema = tasksCount =>
  yup.object().shape({
    verification_tasks: yup
      .array()
      .min(tasksCount, "Please complete all of the tasks")
  });

export const BookingActionsGuestVerificationTasksForm = ({
  submitText,
  isLoading,
  onSubmit,
  closeModal
}) => {
  const { property } = useThreadContext();

  const verificationTasks = property?.home_info?.guest_verification_tasks || [];

  return (
    <Formik
      validationSchema={validationSchema(verificationTasks?.length)}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
      initialValues={{ verification_tasks: [] }}
    >
      <Form className="GuestVerificationForm">
        <HostInstructions property={property} />

        <div
          role="group"
          className="GuestVerificationForm__group"
          aria-labelledby="checkbox-group"
        >
          {verificationTasks.map(({ description, id }) => (
            <div className="Checkbox" key={id}>
              <Field
                id={id}
                type="checkbox"
                name="verification_tasks"
                value={description}
              />
              <label htmlFor={id}>{description}</label>
            </div>
          ))}
        </div>

        <ErrorMessage
          name="verification_tasks"
          component={ErrorMessageContainer}
        />

        <div className="GuestVerificationForm__actions">
          <Button
            handleOnClick={closeModal}
            buttonType="negative"
            message="Cancel"
          />

          <Button
            type="submit"
            buttonType="positive"
            message={submitText}
            disabled={isLoading}
          />
        </div>
      </Form>
    </Formik>
  );
};
