import "./User.scss";

import React, { Component } from "react";

import { auth } from "../services/GoogleAuth";

export default class UserProfile extends Component {
  state = {
    isMenuOpen: false
  };

  handleToggleMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    });
  };

  handleLogout = () => {
    const { history } = this.props;

    auth.signOut().then(() => {
      this.context.showNotification({
        title: "Logged out"
      });

      history.push("/login");
    });
  };

  render() {
    const { isMenuOpen } = this.state;
    const { name, profileImage } = auth.getUserProfile();

    return (
      <div className="User">
        <div className="User__profile">
          <img
            className="User__img"
            src={profileImage}
            alt=""
            onClick={this.handleToggleMenu}
          />

          {isMenuOpen && (
            <React.Fragment>
              <button
                className="User__close-menu"
                onClick={this.handleToggleMenu}
              />

              <div className="User__menu">
                <small>
                  Logged in as: <br />
                  {name}
                </small>

                <button
                  className="User__logout-button "
                  onClick={this.handleLogout}
                >
                  Logout
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
