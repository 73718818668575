import { format, isAfter, isBefore } from "date-fns";
import React from "react";

import { DATE_FORMAT } from "../../utils/constants";
import Alert from "../Alert";

export const PropertyStatusAlerts = ({ property, checkin_date }) => {
  const hasOffboarded =
    property &&
    property.offboarded_from &&
    isBefore(property.offboarded_from, Date.now());
  const hasOnboarded =
    property &&
    property.sorted_from &&
    !isAfter(property.sorted_from, checkin_date);

  if (!property || hasOffboarded) {
    const message = property
      ? "Property offboarded."
      : "Listing not linked or not managed by Houst.";

    return (
      <div className="BookingDetails__alert" data-testid="status-alert">
        <Alert title="Not managed!" message={message} status="fail" />
      </div>
    );
  }

  if (property && !hasOnboarded) {
    return (
      <div className="BookingDetails__alert" data-testid="status-alert">
        <Alert
          title={`Checkin before property's sorted from date (${format(
            property.sorted_from,
            DATE_FORMAT
          )})`}
          message="Please do not reply to this message."
          status="fail"
        />
      </div>
    );
  }

  return null;
};
