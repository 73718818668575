import get from "lodash/get";
import React, { useMemo } from "react";

import { AIRBASE_PROPERTY_URL } from "../../utils/constants";

export const BookingTitle = ({ isMultiplat, booking, thread, property }) => {
  const platformListingLink = useMemo(() => {
    const listingName = isMultiplat ? "Multiplat" : thread?.listing?.name;

    const platformUrl = isMultiplat
      ? get(property, `multiplat_${booking.channel}_url`, null)
      : `https://www.airbnb.com/rooms/${thread.listing.id}`;

    if (!listingName || !platformUrl) {
      return null;
    }

    return (
      <a
        className="BookingDetails__listing"
        href={platformUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {listingName} –
      </a>
    );
  }, [isMultiplat, thread, property, booking]);

  const airbasePropertyLink = useMemo(() => {
    if (!property) {
      return "No property found";
    }

    const { id, home_code } = property;
    return (
      <a
        className="BookingDetails__listing"
        href={`${AIRBASE_PROPERTY_URL}/${id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {home_code}
      </a>
    );
  }, [property]);

  const podAndlocality = useMemo(() => {
    const locality = property?.locality?.name;
    const pod = property?.pod?.name;

    return `(${[locality, pod].filter(Boolean).join(" - ")})`;
  }, [property]);

  return (
    <p className="BookingDetails__listing header" data-testid="listing-name">
      {platformListingLink} {airbasePropertyLink} {podAndlocality}
    </p>
  );
};
