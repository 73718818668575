import { format } from "date-fns";
import React from "react";

import { getLocalTime } from "../utils/datetime";

const LocalTime = ({ locality, date = null }) => {
  const formatLocalTime = () => {
    const localTime = getLocalTime(locality, date);
    return format(localTime, "h:mma");
  };

  return (
    <span>
      The time in {locality.name} is {formatLocalTime()}
    </span>
  );
};

export default LocalTime;
