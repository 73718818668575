import "./Logo.scss";

import React from "react";

import { ReactComponent as HoustLogo } from "../assets/icons/houst-logo-black.svg";

const Logo = () => (
  <div className="Logo">
    <HoustLogo />
  </div>
);

export default Logo;
