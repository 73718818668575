import get from "lodash/get";

export const redirectFromLogin = history => {
  const redirectPath = get(history, "location.redirectPath", null);

  if (redirectPath && redirectPath !== "/login") {
    return history.push(redirectPath);
  }

  history.push("/");
};
