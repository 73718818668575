import React from "react";

import Alert from "../../components/Alert";

export const MaintenanceSuccess = () => {
  return (
    <Alert
      status="success"
      title="Maintenance issue created!"
      message="Please check maintenance inbox in Front."
    />
  );
};
