import "./BookingActions.scss";

import { addHours } from "date-fns";
import React, { useCallback, useState } from "react";

import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { BOOKING_STATUS } from "../../../utils/constants";
import { relativeHoursRemaining } from "../../../utils/format";
import { useThreadContext } from "../ThreadContext";
import { useBookingActionsApiCall } from "./BookingActions/useBookingActionsApiCall";
import { BookingActionsGuestVerificationTasksForm } from "./BookingActionsGuestVerificationTasksForm";

const {
  ACTIVE,
  INQUIRY,
  PENDING,
  AT_CHECKPOINT,
  PREAPPROVED,
  SPECIAL_OFFER
} = BOOKING_STATUS;

const expiryInfoForStatus = (status, thread) => {
  if (status === PENDING) {
    return {
      timeRemaining: thread?.expires_at,
      message: "to approve / deny"
    };
  }

  if (status === INQUIRY) {
    const { posts, responded } = thread;
    const enquiryInitiated = posts.length
      ? posts[posts.length - 1].created_at
      : null;

    return {
      timeRemaining: !responded ? addHours(enquiryInitiated, 24) : null,
      message: "to respond"
    };
  }

  if ([PREAPPROVED, SPECIAL_OFFER].includes(status)) {
    return {
      timeRemaining: thread?.inquiry_special_offer?.expires_at,
      message: "for guest to confirm"
    };
  }

  return { timeRemaining: undefined, message: undefined };
};

const BookingActions = ({ status }) => {
  const { airbnbThreadInfo: thread, refreshThread } = useThreadContext();

  const {
    isLoading,
    handlePreApproveEnquiry,
    handleAcceptReservation,
    handleDeclineReservation,
    isMounted
  } = useBookingActionsApiCall();

  const [verificationModalVisible, setVerificationModalVisible] = useState(
    false
  );

  const toggleVerificationModal = useCallback(
    () => setVerificationModalVisible(!verificationModalVisible),
    [verificationModalVisible]
  );

  const handleSubmitGuestVerification = useCallback(() => {
    const handleApiCall = [ACTIVE, INQUIRY].includes(status)
      ? handlePreApproveEnquiry
      : handleAcceptReservation;

    handleApiCall().then(() => {
      toggleVerificationModal();
      isMounted && refreshThread();
    });
  }, [
    handleAcceptReservation,
    handlePreApproveEnquiry,
    isMounted,
    refreshThread,
    status,
    toggleVerificationModal
  ]);

  const approveSubmitText = [ACTIVE, INQUIRY].includes(status)
    ? "Pre-approve enquiry"
    : "Accept reservation";
  const { message: expiryMessage, timeRemaining } = expiryInfoForStatus(
    status,
    thread
  );

  return (
    <div className="BookingActions">
      {verificationModalVisible && (
        <Modal title="Verifying guest" close={toggleVerificationModal}>
          <BookingActionsGuestVerificationTasksForm
            isLoading={isLoading}
            submitText={approveSubmitText}
            onSubmit={handleSubmitGuestVerification}
            closeModal={toggleVerificationModal}
          />
        </Modal>
      )}

      {[ACTIVE, INQUIRY, PENDING, AT_CHECKPOINT].includes(status) && (
        <Button
          data-testid="approve-button"
          buttonType="positive"
          handleOnClick={toggleVerificationModal}
          message={approveSubmitText}
          disabled={isLoading}
          className="small"
        />
      )}

      {[ACTIVE, INQUIRY].includes(status) && (
        <p className="text-warning" data-testid="deny-info">
          To deny enquiry please log into Airbnb.
        </p>
      )}

      {[PENDING, AT_CHECKPOINT].includes(status) && (
        <Button
          data-testid="decline-button"
          buttonType="negative"
          handleOnClick={handleDeclineReservation}
          message="Decline"
          disabled={isLoading}
          className="small"
        />
      )}

      {timeRemaining && expiryMessage && (
        <span className="BookingActions__expiry">
          {relativeHoursRemaining(timeRemaining, Date.now(), expiryMessage)}
        </span>
      )}
    </div>
  );
};

export default BookingActions;
