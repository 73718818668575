import React from "react";

import { formatDate } from "../../../utils/format";
import { Images } from "../components/Images";
import {
  HOST_RESPONSE,
  ISSUE_LOCATIONS,
  ISSUE_SEVERITY,
  ISSUE_STATUS,
  ISSUE_TYPE,
  MAINTENANCE_PREFERENCES_USER_FRIENDLY,
  REPORTER_TYPE
} from "../constants";
import { useMaintenanceContext } from "../MaintenanceContainer";
import { Actions } from "./Actions";

const DATE_FORMAT = "ddd, Do MMMM, YYYY";

export const MaintenanceDetails = () => {
  const { data } = useMaintenanceContext();
  const {
    created_at: createdAt,
    description,
    images,
    type,
    location,
    updated_at: updatedAt,
    hide_from_host: hideFromHost,
    severity,
    estimate,
    fee_type: feeType,
    supplier_cost: supplierCost,
    job_report: jobReport,
    resolution_notes: resolutionNotes,
    resolution_date: resolutionDate,
    status,
    reporter_name: reporterName,
    reporter_type: reporterType,
    proposed_solution: proposedSolution,
    host_response: hostResponse,
    host_comment: hostComment,
    property: { host_preferences: hostPreferences }
  } = data.maintenance;

  const {
    maintenance_emergencies_spend_formatted: emergenciesSpendFormatted,
    maintenance_non_urgent_spend_formatted: nonUrgentSpendFormatted,
    maintenance_urgent_spend_formatted: urgentSpendFormatted,
    maintenance_emergencies: emergencies,
    maintenance_urgent: urgent,
    maintenance_non_urgent: nonUrgent
  } = hostPreferences;

  const fieldValue = value => value || "Not declared";
  return (
    <>
      <div className="MaintenanceInfoContainer">
        <p className="big-header">Details</p>
        <dl>
          <dt> Issue type</dt>
          <dd data-testid="issue-type"> {fieldValue(ISSUE_TYPE[type])} </dd>
        </dl>

        <dl>
          <dt> Issue location</dt>
          <dd data-testid="issue-location">
            {fieldValue(ISSUE_LOCATIONS[location])}{" "}
          </dd>
        </dl>

        <dl>
          <dt> Severity</dt>
          <dd data-testid="issue-severity">
            {fieldValue(ISSUE_SEVERITY[severity])}{" "}
          </dd>
        </dl>

        <dl>
          <dt> Description</dt>
          <dd data-testid="issue-description"> {fieldValue(description)} </dd>
        </dl>

        <dl>
          <dt> Raised date</dt>
          <dd data-testid="raised-date">
            {fieldValue(formatDate(createdAt, DATE_FORMAT))}{" "}
          </dd>
        </dl>

        <dl>
          <dt> Last updated</dt>
          <dd> {fieldValue(formatDate(updatedAt, DATE_FORMAT))} </dd>
        </dl>

        <dl>
          <dt> Hidden from host</dt>
          <dd> {hideFromHost ? "Yes" : "No"} </dd>
        </dl>

        <Actions />
      </div>

      <div className="MaintenanceInfoContainer">
        <p className="big-header">Images</p>

        <Images images={images} />
      </div>

      <div className="MaintenanceInfoContainer">
        <p className="big-header">Resolution</p>

        <dl>
          <dt> Estimate cost (shared with host)</dt>
          <dd data-testid="estimate-cost"> {fieldValue(estimate)} </dd>
        </dl>

        <dl>
          <dt> Fee type</dt>
          <dd> {fieldValue(feeType)} </dd>
        </dl>

        <dl>
          <dt> Supplier cost</dt>
          <dd> {fieldValue(supplierCost)} </dd>
        </dl>

        <dl>
          <dt> Job report</dt>
          <dd> {fieldValue(jobReport)} </dd>
        </dl>

        <dl>
          <dt> Resolution notes</dt>
          <dd> {fieldValue(resolutionNotes)} </dd>
        </dl>

        <dl>
          <dt> Resolution date</dt>
          <dd> {fieldValue(formatDate(resolutionDate, DATE_FORMAT))} </dd>
        </dl>
      </div>

      <div className="MaintenanceInfoContainer">
        <p className="big-header">Status</p>

        <dl>
          <dt> Status</dt>
          <dd data-testid="issue-status">
            {fieldValue(ISSUE_STATUS[status])}{" "}
          </dd>
        </dl>

        <dl>
          <dt> Reported by</dt>
          <dd data-testid="issue-reporter">
            {fieldValue(`${reporterName}(${REPORTER_TYPE[reporterType]})`)}{" "}
          </dd>
        </dl>

        <dl>
          <dt> Proposed solution</dt>
          <dd> {fieldValue(proposedSolution)} </dd>
        </dl>

        <dl>
          <dt> Host response</dt>
          <dd> {fieldValue(HOST_RESPONSE[hostResponse])} </dd>
        </dl>

        <dl>
          <dt> Host comment</dt>
          <dd> {fieldValue(hostComment)} </dd>
        </dl>
      </div>

      <div className="MaintenanceInfoContainer">
        <p className="big-header">Host preferences</p>

        <dl>
          <dt> Emergencies resolution</dt>
          <dd>{MAINTENANCE_PREFERENCES_USER_FRIENDLY[emergencies]}</dd>
        </dl>

        <dl>
          <dt>Emergencies max spend</dt>
          <dd>{emergenciesSpendFormatted}</dd>
        </dl>

        <dl>
          <dt>Urgent resolution</dt>
          <dd> {MAINTENANCE_PREFERENCES_USER_FRIENDLY[urgent]} </dd>
        </dl>

        <dl>
          <dt>Urgent max spend</dt>
          <dd> {fieldValue(urgentSpendFormatted)} </dd>
        </dl>

        <dl>
          <dt>Non-urgent resolution</dt>
          <dd> {MAINTENANCE_PREFERENCES_USER_FRIENDLY[nonUrgent]} </dd>
        </dl>

        <dl>
          <dt>Non-urgent max spend</dt>
          <dd> {nonUrgentSpendFormatted} </dd>
        </dl>
      </div>
    </>
  );
};
