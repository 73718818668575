import React from "react";
import { useParams } from "react-router-dom";

import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import { formatPropertyError } from "../../utils/format";
import Conversation from "./components/Conversation";
import { useThreadContext } from "./ThreadContext";

export const Messages = () => {
  const {
    error,
    refreshing,
    airbnbThreadInfo,
    airbnbThreadInfoLoading,
    reservation
  } = useThreadContext();
  const { mailBoxName } = useParams();

  if (airbnbThreadInfoLoading) {
    return <Loading message={`Loading messages for ${mailBoxName}...`} />;
  }

  if (error) {
    const formattedMessage = formatPropertyError(error, mailBoxName);

    return (
      <Alert
        title="Something went wrong..."
        message={formattedMessage}
        status="fail"
        type="block"
      />
    );
  }

  if (!airbnbThreadInfo) {
    return null;
  }

  return (
    <>
      <Conversation
        thread={airbnbThreadInfo}
        refreshing={refreshing}
        reservation={reservation}
      />
    </>
  );
};
