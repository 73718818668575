const FRONT_REFERRER_URL = "https://app.frontapp.com/";
const HOST_EMAIL = /^\w+\+([\w-]+)@(?:airsorted|houst|hostmaker)\.(?:uk|com|co)$/;
const DOMAIN_EMAIL = /^[a-z]+@(?:airsorted|houst|hostmaker)\.(?:uk|com|co)$/;
const VRBO = "www.vrbo.com";
const EXPEDIA = "Expedia, Inc.";

export const isRunningInFront = document.referrer.startsWith(
  FRONT_REFERRER_URL
);

export const isAirbnbMessage = recipients => {
  return (
    recipients
      .map(recipient => HOST_EMAIL.exec(recipient.handle))
      .filter(elem => elem).length > 0
  );
};

const getCustomBsMessageGuestEmail = recipients => {
  const guestEmailRegex = /^bookingsync_user:(.+@.+)$/;
  const bookingsyncRecipientMatch = recipients
    .map(recipient => guestEmailRegex.exec(recipient.handle))
    .find(Boolean);
  if (bookingsyncRecipientMatch) {
    return bookingsyncRecipientMatch[1];
  }
};

// Handle custom conversations from our API integration
export const getCustomConversationDetails = ({ subject, recipients }) => {
  // Parse recipients list first for Airbnb messages
  const airbnbRecipientRegex = /^airbox:([\w-+_.]+@[\w-.]+)#(\d+)$/;
  const bookingsyncRecipientRegex = /^airbox:(.+)#(\d+)$/;
  const airbnbRecipientMatch = recipients
    .map(recipient => airbnbRecipientRegex.exec(recipient.handle))
    .find(Boolean);
  const bookingsyncRecipientMatch = recipients
    .map(recipient => bookingsyncRecipientRegex.exec(recipient.handle))
    .find(Boolean);

  const splitedSubject = subject.split(" ");
  const homeCode = splitedSubject.length ? splitedSubject[0] : null;

  // Return the first matching recipient marker
  if (airbnbRecipientMatch) {
    return {
      mailBoxName: airbnbRecipientMatch[1],
      threadId: airbnbRecipientMatch[2],
      homeCode
    };
  }

  if (bookingsyncRecipientMatch) {
    const guestEmail = getCustomBsMessageGuestEmail(recipients);
    return {
      conversationId: bookingsyncRecipientMatch[2],
      guestEmail: guestEmail,
      homeCode
    };
  }
  // Parse recipients list for maintenance
  const maintenanceRecipientRegex = /^maintenance:#(\d+)$/;
  const maintenanceRecipientMatch = recipients
    .map(recipient => maintenanceRecipientRegex.exec(recipient.handle))
    .find(Boolean);

  // Return the first matching recipient marker
  if (maintenanceRecipientMatch) {
    return { maintenanceId: maintenanceRecipientMatch[1] };
  }

  // Fallback to the legacy subject marker if not found
  const subjectRegex = /<([\w-+_.]+@[\w-.]+)#(\d+)>/;
  const subjectMatch = subjectRegex.exec(subject);

  if (subjectMatch) {
    return {
      mailBoxName: subjectMatch[1],
      threadId: subjectMatch[2],
      homeCode
    };
  } else {
    return {
      mailBoxName: null,
      threadId: null,
      maintenanceId: null,
      homeCode: null
    };
  }
};

// Handle standard Airbnb/MP notification emails
export const getEmailMessageDetails = (message, otherMessages = []) => {
  const { body, recipients } = message;
  const airbnbMessage = isAirbnbMessage(recipients);
  const firstMessage = !otherMessages.length ? message : otherMessages[0];

  const response = {
    airbnbMessage,
    mailBoxName: null,
    threadId: null,
    guestEmail: null,
    platformRef: null,
    expediaListingId: null,
    vrboListingId: null
  };

  if (airbnbMessage) {
    return {
      ...response,
      mailBoxName: getAirbnbMailboxName(recipients),
      threadId: getAirbnbThreadId(body)
    };
  }

  if (firstMessage.body && firstMessage.body.includes(VRBO)) {
    return {
      ...response,
      vrboListingId: getVrboListingId(firstMessage.body),
      platformRef: getPlatformRef(firstMessage.body)
    };
  }

  if (firstMessage.text && firstMessage.text.includes(EXPEDIA)) {
    return {
      ...response,
      expediaListingId: getExpediaListingId(firstMessage.text)
    };
  }

  return {
    ...response,
    guestEmail: getGuestEmail(recipients),
    platformRef: getPlatformRef(body)
  };
};

const getAirbnbThreadId = body => {
  const threadRegex = /https:\/\/www.airbnb.[a-z.]+\/hosting\/thread\/(\d+)/;
  const threadMatch = threadRegex.exec(body);

  return threadMatch && threadMatch[1];
};

const getAirbnbMailboxName = recipients => {
  let mailBoxName = null;
  recipients.some(recipient => {
    const email = recipient.handle;
    const mailMatch = HOST_EMAIL.exec(email);
    if (mailMatch) {
      mailBoxName = email;
      return true;
    }

    return false;
  });

  return mailBoxName;
};

// Multiplat guests email "guest@houst.com"; return the first email that does not match this
const getGuestEmail = recipients => {
  const email = recipients
    .map(recipient => recipient.handle)
    .filter(email => !email.match(DOMAIN_EMAIL));

  return email && email[0];
};

const getPlatformRef = body => {
  // B.com references are 10 digits only string
  const bcomRegex = />\s*[0-9]{10}\s*<\/a>/;
  const bcomMsg = bcomRegex.exec(body);

  if (bcomMsg) {
    const codePortion = /[0-9]{10}/.exec(bcomMsg[0]);
    return codePortion[0];
  }

  // Vrbo references are 6 characters inside html tags (like ...>0A4E1R<...)
  const vrboRegex = /\s*>0[9AB][\dA-Z]{4}<\s*/;
  const vrboMsg = vrboRegex.exec(body);

  if (vrboMsg) {
    const codePortion = /0[9AB][\dA-Z]{4}/.exec(vrboMsg[0]);
    return codePortion[0];
  }

  return undefined;
};

const getVrboListingId = body => {
  // Searching for #12345678 then returning digits only
  const regexp = /\s*#\d{7,8}\s*/;
  const listingId = regexp.exec(body);

  if (listingId) {
    return listingId[0].substring(1);
  }

  return undefined;
};

const getExpediaListingId = text => {
  // Searching for `ID: 12345678` then returning digits only
  const regexp = /ID:\s\d{8}/;
  const listingId = regexp.exec(text);

  if (listingId) {
    return listingId[0].substring(4);
  }

  return undefined;
};
