import { areRangesOverlapping } from "date-fns";

import { BOOKING_STATUS } from "../utils/constants";

export const getOverlappingEnquiries = (currentEnquiry, threads = []) => {
  const { ACTIVE, PENDING, PREAPPROVED, INQUIRY } = BOOKING_STATUS;

  // Enquiries must have the correct status, be for the same property as the current enquiry
  // and not be the same enquiry as the one we are checking against
  const enquiries = threads.filter(thread => {
    const currentEnquiryListingId = currentEnquiry.listing?.id;
    const threadListingId = thread.listing?.id;
    const status = thread.reservation?.status;

    return (
      [ACTIVE, PENDING, PREAPPROVED, INQUIRY].includes(status) &&
      currentEnquiryListingId === threadListingId &&
      thread.id !== currentEnquiry.id
    );
  });

  if (!enquiries.length) {
    return false;
  }

  const overlappingEnquiries = enquiries.filter(enquiry => {
    return areRangesOverlapping(
      currentEnquiry.reservation?.checkin_date,
      currentEnquiry.reservation?.checkout_date,
      enquiry.reservation?.checkin_date,
      enquiry.reservation?.checkout_date
    );
  });

  if (!overlappingEnquiries.length) {
    return false;
  }

  return overlappingEnquiries;
};
