import "./Login.scss";

import React, { Component } from "react";

import Alert from "../../components/Alert";
import Logo from "../../components/Logo";
import { AppContext } from "../../data/AppContextContainer";
import { isRunningInFront } from "../../services/front";
import { auth } from "../../services/GoogleAuth";
import { redirectFromLogin } from "../../services/routing";
import { GOOGLE_DOMAINS } from "../../utils/constants";

export default class Login extends Component {
  static contextType = AppContext;

  state = {
    error: null,
    loading: true
  };

  componentDidMount() {
    const { history } = this.props;

    if (isRunningInFront || auth.isSignedIn()) {
      return history.push("/");
    }

    window.gapi.signin2.render("login-button", {
      scope: "profile email",
      theme: "light",
      longtitle: true,
      onsuccess: this.handleAuthSuccess,
      onfailure: this.handleAuthFailure
    });
  }

  handleAuthFailure = () => {
    this.setState({
      error: "Failed to log in, please try again."
    });
  };

  handleAuthSuccess = user => {
    const { history } = this.props;
    const domain = user.getHostedDomain();

    if (!domain || !GOOGLE_DOMAINS.includes(domain)) {
      return auth.signOut().then(() => {
        this.setState({
          error: `This account is not an Airsorted/Houst Google account. 
            Please log out of this account on any tabs you may have open (e.g. Gmail) and try again.`
        });
      });
    }

    redirectFromLogin(history);
  };

  render() {
    const { error } = this.state;

    return (
      <div className="Login">
        <Logo />

        <h3>Airbox Messenger</h3>

        {error && (
          <div className="Login__error">
            <Alert
              title={"Something went wrong..."}
              message={error}
              status="fail"
              type="block"
            />
          </div>
        )}

        <div className="Login__button">
          <div id="login-button">
            <p>
              <i>Loading...</i>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
