import React from "react";
import { useParams } from "react-router-dom";

import AppHeader from "../../components/AppHeader";
import Thread from "./Thread";
import { ThreadContextProvider } from "./ThreadContext";

const ThreadContainer = () => {
  const { mailBoxName } = useParams();

  return (
    <ThreadContextProvider>
      <AppHeader
        title={`Thread for: ${mailBoxName}`}
        link={`/mailbox/${mailBoxName}`}
      />

      <Thread />
    </ThreadContextProvider>
  );
};

export default ThreadContainer;
