import React, { Component } from "react";

export default class ErrorHandler extends Component {
  state = {
    error: false
  };

  componentDidCatch(error) {
    this.setState({
      error
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div>
          <h1>
            Oops!{" "}
            <span role="img" aria-label="Oops">
              🤬
            </span>
          </h1>
          <p>
            Something went wrong, i.e. tech broke something{" "}
            <span role="img" aria-label="Oops">
              😵
            </span>
          </p>
          <p>
            Try refreshing the page and if that doesn't work please take a
            screenshot and post in #tech-support.
          </p>
          <p className="text-error">{error.toString()}</p>
        </div>
      );
    }

    return children;
  }
}
