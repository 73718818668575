import "./Input.scss";

import classNames from "classnames";
import { ErrorMessage, Field } from "formik";
import React from "react";

import { ErrorMessageContainer } from "./ErrorMessageContainer";

export const Input = ({
  name,
  type,
  component,
  placeholder,
  id,
  label,
  invalid,
  rows,
  children,
  labelWithInfo,
  infoText
}) => {
  return (
    <>
      <label htmlFor={name} className="Input__label">
        {label}
      </label>

      <Field
        name={name}
        component={component}
        id={id || name}
        type={type}
        placeholder={placeholder}
        rows={rows || null}
        className={classNames("Input", {
          "Input--invalid": invalid
        })}
      >
        {children}
      </Field>

      <ErrorMessage name={name} component={ErrorMessageContainer} />
    </>
  );
};
