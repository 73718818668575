import "./App.scss";

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import ErrorHandler from "../../components/ErrorHandler";
import Notifications from "../../components/Notifications";
import { AppContextContainer } from "../../data/AppContextContainer";
import { loadEnvironment } from "../../utils/scripts";
import Empty from "../error/Empty";
import NotFound from "../error/NotFound";
import InboxContainer from "../inbox/InboxContainer";
import Login from "../login/Login";
import MaintenanceContainer from "../maintenance/MaintenanceContainer";
import { MaintenanceSuccess } from "../maintenance/MaintenanceSuccess";
import NewMaintenance from "../maintenance/NewMaintenance";
import SearchContainer from "../search/SearchContainer";
import ThreadContainer from "../thread/ThreadContainer";
import AppRouter from "./components/AppRouter";
import AuthRoute from "./components/AuthRoute";
import { Footer } from "./components/Footer";

export default class App extends Component {
  state = {
    isEnvironmentLoaded: false,
    error: null
  };

  componentDidMount() {
    loadEnvironment()
      .then(() => {
        this.setState({ isEnvironmentLoaded: true });
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  render() {
    const { isEnvironmentLoaded, error } = this.state;

    if (error) {
      return <p className="App-loading">{error}</p>;
    }

    if (!isEnvironmentLoaded) {
      return (
        <p className="App-loading">Loading environment, won't be a second...</p>
      );
    }

    return (
      <div className="app-background">
        <div className="App">
          <BrowserRouter>
            <AppContextContainer>
              <Notifications />

              <AppRouter>
                <ErrorHandler>
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <AuthRoute
                      exact
                      path="/mailbox/:mailBoxName"
                      component={InboxContainer}
                    />
                    <AuthRoute
                      exact
                      path="/mailbox/:mailBoxName/listing/:listingId"
                      component={props => <InboxContainer {...props} />}
                    />
                    <AuthRoute
                      exact
                      path="/mailbox/:mailBoxName/thread/:threadId/:homeCode"
                      component={ThreadContainer}
                    />
                    <AuthRoute
                      exact
                      path="/search/bcom/conversation/:guestEmail/:conversationId/"
                      component={SearchContainer}
                    />
                    <AuthRoute
                      exact
                      path="/search/bcom/:guestEmail/:platformRef?"
                      component={SearchContainer}
                    />
                    <AuthRoute
                      exact
                      path="/search/vrbo/:vrboListingId/:platformRef?"
                      component={SearchContainer}
                    />
                    <AuthRoute
                      exact
                      path="/search/expedia/:expediaListingId"
                      component={SearchContainer}
                    />
                    <AuthRoute
                      exact
                      path="/maintenance"
                      component={NewMaintenance}
                    />
                    <AuthRoute
                      exact
                      path="/maintenance/success"
                      component={MaintenanceSuccess}
                    />
                    <AuthRoute
                      exact
                      path="/maintenance/:maintenanceId?"
                      component={MaintenanceContainer}
                    />

                    <AuthRoute exact path="/not-found" component={NotFound} />
                    <AuthRoute component={Empty} />
                  </Switch>
                </ErrorHandler>
                <Footer />
              </AppRouter>
            </AppContextContainer>
          </BrowserRouter>
        </div>
      </div>
    );
  }
}
