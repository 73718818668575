import React from "react";
import { useParams } from "react-router-dom";

import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import ThreadBookingDetails from "../../components/ThreadBookingDetails";
import { BOOKING_STATUS } from "../../utils/constants";
import { formatPropertyError } from "../../utils/format";
import Alteration from "./components/Alteration";
import BookingStatus from "./components/BookingStatus";
import EnquiryChecker from "./components/EnquiryChecker";
import GuestDetails from "./components/GuestDetails";
import { useThreadContext } from "./ThreadContext";

export const AirbnbData = () => {
  const {
    error,
    refreshThread,
    airbnbThreadInfo,
    propertyInfo,
    airbnbThreadInfoLoading,
    reservation
  } = useThreadContext();
  const { mailBoxName } = useParams();

  const displayBookingStatus = () => {
    const status = reservation?.status || airbnbThreadInfo.status;
    const { ACCEPT } = BOOKING_STATUS;

    // Hide booking status banner for accepted bookings
    return status !== ACCEPT;
  };

  if (airbnbThreadInfoLoading) {
    return (
      <Loading message={`Loading booking information for ${mailBoxName}...`} />
    );
  }

  if (error) {
    const formattedMessage = formatPropertyError(error, mailBoxName);

    return (
      <Alert
        title="Something went wrong..."
        message={formattedMessage}
        status="fail"
        type="block"
      />
    );
  }

  if (!airbnbThreadInfo || !propertyInfo) {
    return null;
  }

  return (
    <>
      <EnquiryChecker />

      <ThreadBookingDetails />

      {displayBookingStatus() && <BookingStatus />}

      <Alteration
        reservation={reservation}
        thread={airbnbThreadInfo}
        mailBoxName={mailBoxName}
        refreshThread={refreshThread}
      />

      <GuestDetails />
    </>
  );
};
