import { addMinutes, differenceInDays, format, isToday } from "date-fns";

// Needed as when new Date() with no args is converted to a string (via date-fns format)
// it is converted to local time. To get round this we explicitly set the date with UTC values
// https://github.com/date-fns/date-fns/issues/376
export const getUTCDateNow = date => {
  const d = new Date(date || Date.now());

  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds()
  );
};

export const getLocalTime = (locality, date = Date.now()) => {
  const minutesOffset = locality.utc_offset;
  const utcNow = getUTCDateNow(date);
  return addMinutes(utcNow, minutesOffset);
};

export const naiveToUtc = datetime => {
  return datetime.endsWith("Z")
    ? datetime
    : datetime.replace(" ", "T").concat("Z");
};

export const utcToNaive = datetime => {
  return datetime.endsWith("Z")
    ? datetime.replace("T", " ").slice(0, -1)
    : datetime;
};

export const renderCreatedAtDateTime = datetime => {
  const utcDatetime = naiveToUtc(datetime);

  return `${
    isToday(utcDatetime) ? "today" : format(utcDatetime, "Do MMM YYYY")
  } at ${format(utcDatetime, "h:mma")}`;
};

export const getMidday = dateString => {
  // Date format from schema: "Tue, 08 Jul 2014 00:00:00 GMT"
  if (dateString.includes(",")) {
    return dateString.replace("00:00", "12:00");
  }

  // Bookings dates are datetime strings, while enquiry dates are date strings
  // 2019-12-16T00:00:00 or 2019-12-16
  const timeStrippedDate = dateString.includes("T")
    ? dateString.substring(0, dateString.indexOf("T") + 1)
    : dateString.concat("T");

  return timeStrippedDate.concat("12:00:00");
};

export const getNumberOfDays = (checkout_date, checkin_date) => {
  return differenceInDays(getMidday(checkout_date), getMidday(checkin_date));
};

export const getDate = datetime => {
  const d = new Date(datetime);

  return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
};
