import React from "react";

import Alert from "../../components/Alert";
import SearchBar from "../../components/SearchBar";
import { isRunningInFront } from "../../services/front";

const Empty = () => {
  const message = isRunningInFront
    ? "Select a conversation to get started."
    : "Search for a hosts mailbox using the home code to get started.";

  return (
    <div className="AppHeader">
      <SearchBar />
      <div className="Error">
        <Alert title="No conversation selected..." message={message} />
      </div>
    </div>
  );
};

export default Empty;
