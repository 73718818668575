import { differenceInHours, format, isToday } from "date-fns";
import Linkify from "linkifyjs/react";
import get from "lodash/get";
import React from "react";

import { DATE_FORMAT } from "./constants";
import { getMidday } from "./datetime";

export const renderCreatedAtDateTime = datetime => {
  return `${
    isToday(datetime) ? "today" : format(datetime, "Do MMM YYYY")
  } at ${format(datetime, "h:mma")}`;
};

export const renderMessageNewLines = message => {
  return message.split("\n").map((item, key) => {
    return (
      <span key={key}>
        <Linkify options={{ className: null }}>{item}</Linkify>
        <br />
      </span>
    );
  });
};

export const relativeHoursRemaining = (
  time,
  deadline = Date.now(),
  message
) => {
  if (!time) {
    return null;
  }

  const hoursRemaining = differenceInHours(time, deadline);

  if (hoursRemaining > 0) {
    const hourUnit = hoursRemaining === 1 ? "hour" : "hours";
    return `${hoursRemaining} ${hourUnit} ${message}`;
  }

  return null;
};

export const formatPropertyError = (error, queryValue = "") => {
  const errorMessage = get(error, "response.data.error", null);
  const errorCode = error?.response?.status;

  if (errorMessage) {
    return (
      <React.Fragment>
        {errorCode === 401 && (
          <div className="Alert__message--token">
            <hr />
            <div>
              <strong>Access token might be expired.</strong>
            </div>
            <span>Try refreshing it using </span>
            <a
              href="https://airlink.houst.com/dashboard"
              target="_blank"
              rel="noopener noreferrer"
            >
              Airlink
            </a>
            <span> before contacting tech support.</span> <hr />
          </div>
        )}

        {errorMessage}
      </React.Fragment>
    );
  }

  return "Please try refreshing the page.";
};

export const formatTime = timeString => {
  if (!timeString) {
    return null;
  }

  // "2018-01-01" is an arbitrary date.
  // Used to make a datetime string, for formatting into a time string
  const dateTime = `2018-01-01 ${timeString}`;

  return format(dateTime, "HH:mm");
};

export const capitaliseString = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatCannedMessages = cannedMessages => {
  if (!cannedMessages || !cannedMessages.length) {
    return null;
  }

  return cannedMessages.map(groupedMessages => ({
    label: groupedMessages.type,
    options: groupedMessages.messages.map(message => ({
      label: message.title,
      value: message.contents
    }))
  }));
};

export const setSelectMenuTheme = theme => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: "#3498db"
    }
  };
};

export const formatDate = (dateString, dateFormat = DATE_FORMAT) => {
  if (!dateString) {
    return null;
  }

  // When daylight savings time ends the clocks move back an hour
  // This causes a date to move to the previous date if datetime was set to midnight
  // This can be overcome by setting datetime object to midday before stripping date
  const middayDate = getMidday(dateString);

  return format(middayDate, dateFormat);
};

export const getFormattedDatetime = (dateString, timeString = null) => {
  if (!dateString) {
    return null;
  }

  const formattedDate = formatDate(dateString);
  const formattedTime = formatTime(timeString);

  return formattedTime
    ? `${formattedDate}, ${formattedTime}`
    : `${formattedDate}`;
};

export const formatPrice = (amount, currency) => {
  if (!currency) {
    return amount;
  }

  // 'undefined' forces this to use user's (browser's) locale
  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency
  });

  return formatter.format(amount);
};
