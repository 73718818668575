import get from "lodash/get";
import { Component } from "react";
import { withRouter } from "react-router-dom";

import { AppContext } from "../../../data/AppContextContainer";
import { registerAuthInterceptors, setAuthSecret } from "../../../services/api";
import {
  getCustomConversationDetails,
  getEmailMessageDetails,
  isRunningInFront
} from "../../../services/front";

export class AppRouter extends Component {
  static contextType = AppContext;

  componentDidMount() {
    if (isRunningInFront) {
      setAuthSecret(window.location.search);
      window.Front.on("conversation", this.handleConversation);
      window.Front.setPanelWidth(500);
    } else {
      registerAuthInterceptors(errorMessage => {
        const { history } = this.props;
        const redirectPath = get(history, "location.pathname", "/");

        this.context.showNotification({
          title: "Authentication error",
          message: `${errorMessage} - please log in again.`,
          type: "negative"
        });

        history.push({ pathname: "/login", redirectPath });
      });
    }
  }

  routeTo = path => {
    this.props.history.push(`${path}`);
  };

  handleConversation = ({ conversation, message, otherMessages }) => {
    if (conversation && conversation.message_type === "custom") {
      const {
        mailBoxName,
        threadId,
        maintenanceId,
        homeCode,
        conversationId,
        guestEmail
      } = getCustomConversationDetails(message, conversation);

      if (mailBoxName && threadId) {
        return this.routeTo(
          `/mailbox/${mailBoxName}/thread/${threadId}/${homeCode}`
        );
      } else if (conversationId && guestEmail) {
        return this.routeTo(
          `/search/bcom/conversation/${guestEmail}/${conversationId}`
        );
      } else if (maintenanceId) {
        return this.routeTo(`/maintenance/${maintenanceId}`);
      } else {
        return this.routeTo("/not-found");
      }
    } else {
      return this.handleEmailMessage(message, otherMessages);
    }
  };

  handleEmailMessage = (message, otherMessages) => {
    if (!message) {
      return this.routeTo("/not-found");
    }

    const {
      airbnbMessage,
      mailBoxName,
      threadId,
      guestEmail,
      platformRef,
      vrboListingId,
      expediaListingId
    } = getEmailMessageDetails(message, otherMessages);

    if (airbnbMessage) {
      if (threadId) {
        return this.routeTo(`/mailbox/${mailBoxName}/thread/${threadId}`);
      }

      return this.routeTo(`/mailbox/${mailBoxName}`);
    }

    // We match Booking.com messages with guest email
    if (guestEmail) {
      if (platformRef) {
        return this.routeTo(`/search/bcom/${guestEmail}/${platformRef}`);
      }

      return this.routeTo(`/search/bcom/${guestEmail}`);
    }

    // We match VRBO messages with their reference or listing ID
    if (vrboListingId) {
      if (platformRef) {
        return this.routeTo(`/search/vrbo/${vrboListingId}/${platformRef}`);
      }

      return this.routeTo(`/search/vrbo/${vrboListingId}`);
    }

    // We can match Expedia messages with listing ID only
    if (expediaListingId) {
      return this.routeTo(`/search/expedia/${expediaListingId}`);
    }
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(AppRouter);
