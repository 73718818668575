import "./Messenger.scss";

import classNames from "classnames";
import React, { useRef } from "react";

import { ReactComponent as BinIcon } from "../../assets/icons/bin.svg";

export const MessengerTextArea = ({
  value,
  onChange,
  isModified,
  isExpanded,
  onClear,
  onFocus
}) => {
  const textareaRef = useRef(null);

  const moveCarret = () => {
    // Move the cursor to the first blank line
    const cursorPosition = textareaRef.current.value.lastIndexOf("\n\n");
    textareaRef.current.setSelectionRange(cursorPosition, cursorPosition);
  };

  const handleClearButtonClick = () => {
    onClear();
    textareaRef.current.focus();

    // Workaround - wait a tick as value is handled by useEffect, reducers etc
    // So the easiest way to get the latest value is to wait a tick
    // No content entered by user so move the cursor to the first blank line
    setTimeout(moveCarret);
  };

  const handleOnFocus = () => {
    if (!isModified) {
      moveCarret();
    }
    onFocus();
  };

  const onChangeInternal = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <div className="Messenger__textarea-container">
      <textarea
        value={value ?? ""}
        onFocus={handleOnFocus}
        aria-label="message"
        onChange={onChangeInternal}
        ref={textareaRef}
        className={classNames("Messenger__textarea", {
          "Messenger__textarea--active": isExpanded
        })}
      />

      <button className="Messenger__clear" onClick={handleClearButtonClick}>
        <BinIcon />
        Clear
      </button>
    </div>
  );
};
