import "./GuestFlagForm.scss";

import get from "lodash/get";
import React, { Component } from "react";

import Button from "../../../components/Button";
import { AppContext } from "../../../data/AppContextContainer";
import { flagGuest } from "../../../services/api";

export default class GuestFlagForm extends Component {
  static contextType = AppContext;

  state = {
    type: "info",
    reason: ""
  };

  handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleFlagGuest = e => {
    const { closeModal, guest, refreshThread } = this.props;
    const { type, reason } = this.state;

    e.preventDefault();

    flagGuest({
      airbnb_user_id: guest.id,
      type,
      reason
    })
      .then(() => {
        closeModal();
        refreshThread();
      })
      .catch(error => {
        closeModal();

        this.context.showNotification({
          title: "Flagging guest failed.",
          message: get(error, "response.data.error", "Please try again."),
          type: "negative"
        });
      });
  };

  render() {
    const { type, reason } = this.state;
    const { closeModal } = this.props;

    return (
      <form className="GuestFlagForm">
        <div className="GuestFlagForm__group">
          <label>Flag type:</label>

          <select name="type" value={type} onChange={this.handleOnChange}>
            <option value="info">Info</option>
            <option value="warning">Warning</option>
          </select>
        </div>

        <div className="GuestFlagForm__group">
          <label>Reason:</label>

          <textarea
            name="reason"
            value={reason}
            onChange={this.handleOnChange}
            placeholder="E.g. guest intended to throw a party at the property."
          />
        </div>

        <div className="GuestFlagForm__actions">
          <Button
            handleOnClick={closeModal}
            buttonType="negative"
            message="Cancel"
          />

          <Button
            handleOnClick={this.handleFlagGuest}
            type="submit"
            disabled={!reason}
            buttonType="positive"
            message="Flag guest"
          />
        </div>
      </form>
    );
  }
}
