import "./Maintenance.scss";

import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import EditMaintenance from "./components/EditMaintenance";
import { MaintenanceDetails } from "./components/MaintenanceDetails";
import { useMaintenanceContext } from "./MaintenanceContainer";

const Maintenance = () => {
  const { loading, error, data } = useMaintenanceContext();
  const { maintenanceId } = useParams();
  const [editMode, setEditMode] = useState(false);

  const handleEditClick = () => {
    setEditMode(true);
  };

  if (loading) {
    return <Loading message={`Loading maintenance ${maintenanceId}...`} />;
  }

  if (error) {
    return (
      <Alert
        title="Something went wrong..."
        message={error.toString()}
        status="fail"
        type="block"
      />
    );
  }

  if (!data || !data.maintenance) {
    return null;
  }

  if (editMode) {
    return (
      <EditMaintenance
        maintenanceId={maintenanceId}
        setEditMode={setEditMode}
      />
    );
  }

  return (
    <>
      <div className="MaintenanceItem" data-testid="maintenance-ui">
        <Button
          buttonType="link"
          className="edit-button"
          onClick={handleEditClick}
        >
          Edit issue
        </Button>

        <MaintenanceDetails />
      </div>
    </>
  );
};

export default Maintenance;
