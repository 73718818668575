import "./Conversation.scss";

import { format } from "date-fns";
import React from "react";

import ConversationFooter from "../../../components/ConversationFooter";
import Loading from "../../../components/Loading";
import { DATE_FORMAT } from "../../../utils/constants";
import { renderCreatedAtDateTime } from "../../../utils/datetime";
import { renderMessageNewLines } from "../../../utils/format";

const Conversation = ({ thread, refreshing, reservation, booking }) => {
  const posts = thread.posts;
  const checkinDate = reservation?.checkin_date || booking?.checkin;
  const checkoutDate = reservation?.checkout_date || booking?.checkout;
  const numberOfGuests = reservation?.number_of_guests || booking?.num_guests;

  const renderBookingDetails = post => {
    if (checkinDate && checkoutDate) {
      return (
        <div className="Conversation__booking-details">
          <small>
            <span role="img" aria-label="Date icon">
              &#128197;{" "}
            </span>
            <strong>{format(checkinDate, DATE_FORMAT)}</strong> to{" "}
            <strong>{format(checkoutDate, DATE_FORMAT)}</strong>{" "}
            {numberOfGuests && <span>for {numberOfGuests} guests </span>}
          </small>
        </div>
      );
    }
  };

  const renderConversation = post => {
    const key = post.created_at;

    let attachments;
    if (post.attachment_fallback_urls) {
      attachments = post.attachment_fallback_urls.map(
        attachment_fallback_url => (
          <div key={key} className="Conversation__image">
            <a
              href={attachment_fallback_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={attachment_fallback_url} alt="Attachment" />
            </a>
          </div>
        )
      );
    }

    if (post.message) {
      let messageTypeClass;
      if (post.is_guest) {
        messageTypeClass = "received";
      } else if (post.is_guest === false) {
        messageTypeClass = "sent";
      } else {
        messageTypeClass = "third-party";
      }

      return (
        <div
          key={key}
          className={`Conversation__message Conversation__message--${messageTypeClass}`}
          data-testid="conversation-message"
        >
          {renderMessageNewLines(post.message)}

          {attachments}

          <ConversationFooter
            datetime={post.created_at}
            operator={post.user_email}
            message={post.message}
          />

          {renderBookingDetails(post)}
        </div>
      );
    }

    if (post.attachment_fallback_urls && !post.message) {
      return attachments;
    }

    if (post.action_type) {
      return (
        <div key={key} className={`Conversation__action`}>
          <strong>{post.action_type}</strong>{" "}
          {renderCreatedAtDateTime(post.created_at)} by{" "}
          <em>{post.user_email}</em>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="Conversation">
      {refreshing && (
        <Loading
          className="Conversation__loading"
          message="Loading new messages..."
        />
      )}

      {posts.map(renderConversation)}
    </div>
  );
};

export default Conversation;
