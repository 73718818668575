import get from "lodash/get";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../../data/AppContextContainer";
import {
  acceptReservation,
  declineReservation,
  preApproveEnquiry
} from "../../../../services/api";
import { useThreadContext } from "../../ThreadContext";

export const useBookingActionsApiCall = () => {
  const { airbnbThreadInfo: thread } = useThreadContext();
  const confirmationCode = thread.reservation?.confirmation_code;
  const { showNotification } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef();
  const { mailBoxName } = useParams();

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  // Handles succcess and failure of API calls with notifications
  const handleAPICall = useCallback(
    (request, { success: successMessage, fail: failMessage }) => {
      setIsLoading(true);

      return request()
        .then(() => {
          showNotification({
            title: successMessage,
            link: `/mailbox/${mailBoxName}/thread/${thread.id}`
          });
        })
        .catch(error => {
          showNotification({
            title: failMessage,
            message: get(
              error,
              "response.data.error",
              "Please refresh the app and try again."
            ),
            type: "negative",
            link: `/mailbox/${mailBoxName}/thread/${thread.id}`
          });
        })
        .finally(() => {
          if (isMounted.current) {
            setIsLoading(false);
          }
        });
    },
    [mailBoxName, showNotification, thread.id]
  );

  const handlePreApproveEnquiry = useCallback(
    () =>
      handleAPICall(() => preApproveEnquiry(mailBoxName, thread.id), {
        success: "Enquiry preapproved.",
        fail: "Failed to preapprove enquiry."
      }),
    [handleAPICall, mailBoxName, thread.id]
  );

  const handleAcceptReservation = useCallback(
    () =>
      handleAPICall(
        () => acceptReservation(mailBoxName, confirmationCode, thread.id),
        {
          success: "Reservation accepted successfully.",
          fail: "Failed to accept reservation."
        }
      ),
    [confirmationCode, handleAPICall, mailBoxName, thread.id]
  );

  const handleDeclineReservation = useCallback(
    () =>
      handleAPICall(
        () => declineReservation(mailBoxName, confirmationCode, thread.id),
        {
          success: "Reservation declined successfully.",
          fail: "Failed to decline reservation."
        }
      ),
    [confirmationCode, handleAPICall, mailBoxName, thread.id]
  );

  return {
    isLoading,
    isMounted: isMounted.current,
    handlePreApproveEnquiry,
    handleAcceptReservation,
    handleDeclineReservation
  };
};
