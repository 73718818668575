import React from "react";

import { ReactComponent as MapIcon } from "../../../assets/icons/google_maps.svg";
import { GOOGLE_MAPS_URL } from "../../../utils/constants";

export const PropertyMapLink = ({ lat, lng }) => {
  if (!(lat && lng)) {
    return null;
  }

  return (
    <a
      href={`${GOOGLE_MAPS_URL}?q=${lat},${lng}`}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="map"
    >
      <MapIcon />
    </a>
  );
};
