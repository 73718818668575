import "./Version.scss";

import React from "react";

const VERSION = "3.0.0";

const Version = () => {
  return (
    <div className="Version">
      <p className="Version__message">
        <small>Version: {VERSION}</small>
      </p>
    </div>
  );
};

export default Version;
