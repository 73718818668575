import get from "lodash/get";
import React from "react";
import { Redirect, Route } from "react-router-dom";

import { isRunningInFront } from "../../../services/front";
import { auth } from "../../../services/GoogleAuth";

const AuthRoute = ({ component: Component, ...rest }) => {
  if (isRunningInFront) {
    return (
      // Passing a random key to force rendering on the same path
      // Useful when receiving same email on different inboxes in Front
      <Route
        {...rest}
        render={props => <Component {...props} key={Date.now()} />}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          auth.isSignedIn() ? (
            <React.Fragment>
              <Component {...props} />
            </React.Fragment>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                redirectPath: get(props, "location.pathname", "/")
              }}
            />
          )
        }
      />
    );
  }
};

export default AuthRoute;
