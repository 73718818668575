import React, { useState } from "react";

import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import CancellationPenalties from "./CancellationPenalties";
import CancellationReason from "./CancellationReason";

const Cancellation = ({ mailBoxName, reservation, refreshThread }) => {
  const [cancellationDetails, setCancellationDetails] = useState(null);
  const [showPenaltiesModal, setShowPenaltiesModal] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);

  const togglePenaltiesModal = () => {
    setShowPenaltiesModal(!showPenaltiesModal);
  };

  const toggleReasonModal = () => {
    setShowReasonModal(!showReasonModal);
  };

  return (
    <>
      {showPenaltiesModal && (
        <Modal title="Cancel reservation">
          <CancellationPenalties
            mailBoxName={mailBoxName}
            reservation={reservation}
            setDetails={setCancellationDetails}
            onCancel={togglePenaltiesModal}
            onContinue={() => {
              toggleReasonModal();
              togglePenaltiesModal();
            }}
          />
        </Modal>
      )}

      {showReasonModal && (
        <Modal title="Cancel reservation">
          <CancellationReason
            applicableReasons={cancellationDetails}
            mailBoxName={mailBoxName}
            confirmationCode={reservation.confirmation_code}
            onCancel={toggleReasonModal}
            refreshThread={refreshThread}
          />
        </Modal>
      )}

      <Button
        data-testid="open-cancellation"
        onClick={togglePenaltiesModal}
        buttonType="link"
        message="Cancel this reservation"
      />
    </>
  );
};

export default Cancellation;
