import axios from "axios";
import { differenceInCalendarDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import Alert from "../../../components/Alert";
import Loading from "../../../components/Loading";
import { getOverlappingEnquiries } from "../../../services/airbnb";
import { getMailBox } from "../../../services/api";
import { BOOKING_STATUS, DATE_FORMAT } from "../../../utils/constants";
import { useThreadContext } from "../ThreadContext";

const EnquiryChecker = () => {
  const { mailBoxName, homeCode } = useParams();
  const { airbnbThreadInfo: thread, reservation } = useThreadContext();
  const status = reservation?.status;
  const { ACTIVE, PENDING, PREAPPROVED, INQUIRY } = BOOKING_STATUS;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [overlappingEnquiries, setOverlappingEnquiries] = useState(null);

  useEffect(() => {
    // Ignore if thread cannot be matched to any of our listings
    if (!thread.listing) {
      return setLoading(false);
    }

    // Only check for overlapping enquiries if the current thread is an active enquiry
    if (![ACTIVE, PENDING, PREAPPROVED, INQUIRY].includes(status)) {
      return setLoading(false);
    }

    const cancelRequest = axios.CancelToken.source();

    getMailBox(mailBoxName, thread.listing.id, cancelRequest.token)
      .then(({ data }) => {
        const overlappingEnquiresForListing = getOverlappingEnquiries(
          thread,
          data.threads
        );

        if (overlappingEnquiresForListing) {
          setOverlappingEnquiries(overlappingEnquiresForListing);
        }

        setLoading(false);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return console.info(error.message);
        }

        setLoading(false);
        setError(
          "Something went wrong fetching other enquiries, please refresh."
        );
      });

    return () => {
      cancelRequest.cancel("Cancelling enquiry overlap check");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailBoxName, status, thread, thread.id]);

  if (error) {
    return (
      <Alert title="Something went wrong..." message={error} status="fail" />
    );
  }

  if (loading) {
    return <Loading message={"Checking for overlapping enquiries..."} />;
  }

  if (overlappingEnquiries) {
    const enquiryPlural =
      overlappingEnquiries.length === 1 ? "enquiry" : "enquiries";
    const title = `Overlapping ${enquiryPlural} found!`;

    const message = overlappingEnquiries.map(enquiry => {
      const checkIn = enquiry.reservation?.checkin_date;
      const checkOut = enquiry.reservation?.checkout_date;
      const guestName = enquiry.guest?.first_name || "No guest name";

      return (
        <React.Fragment key={enquiry.id}>
          {guestName}, {format(checkIn, DATE_FORMAT)} to{" "}
          {format(checkOut, DATE_FORMAT)},{" "}
          <strong>{differenceInCalendarDays(checkOut, checkIn)} nights</strong>{" "}
          -{" "}
          <Link to={`/mailbox/${mailBoxName}/thread/${enquiry.id}/${homeCode}`}>
            view
          </Link>
          <br />
        </React.Fragment>
      );
    });

    return <Alert title={title} message={message} status="pending" />;
  }

  return null;
};

export default EnquiryChecker;
