import { generateMessageTemplate } from "./utils";

export const ACTION_DRAFT_LOADED = "draft_loaded";
export const ACTION_SET_VALUE = "set_value";
export const ACTION_ADD_CANNED_MESSAGE = "add_canned_message";
export const ACTION_RESET = "reset";

export const createInitialState = ({ property, guestName }) => ({
  draftLoaded: false,
  cannedMessages: [],
  property,
  guestName,
  value: generateMessageTemplate(property, guestName)
});

export const reducer = (state, { type, value: actionValue }) => {
  const initialState = createInitialState({
    property: state.property,
    guestName: state.guestName
  });

  switch (type) {
    case ACTION_DRAFT_LOADED: {
      return {
        ...state,
        draftLoaded: true
      };
    }
    case ACTION_SET_VALUE: {
      if (actionValue === "") {
        return {
          ...initialState,
          draftLoaded: state.draftLoaded
        };
      } else {
        return {
          ...state,
          value: actionValue
        };
      }
    }

    case ACTION_ADD_CANNED_MESSAGE: {
      const cannedMessages = [...state.cannedMessages, actionValue];

      return {
        ...state,
        cannedMessages,
        // Regenerate the value to include the new canned message
        value: generateMessageTemplate(
          state.property,
          state.guestName,
          cannedMessages
        )
      };
    }
    case ACTION_RESET: {
      return {
        ...initialState,
        draftLoaded: state.draftLoaded
      };
    }

    default:
      throw Error("Unknown action: " + type);
  }
};
