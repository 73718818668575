import "./Thread.scss";

import React from "react";

import { AirbnbMessengerContainer } from "../../components/messenger/AirbnbMessengerContainer";
import { AirbnbData } from "./AirbnbData";
import { Messages } from "./Messages";
import { Property } from "./Property";

const Thread = () => {
  return (
    <div className="Thread" data-testid="thread">
      <AirbnbData />

      <Property />

      <Messages />

      <AirbnbMessengerContainer />
    </div>
  );
};

export default Thread;
