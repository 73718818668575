import "../Maintenance.scss";

import { Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import Button from "../../../components/Button";
import { Input } from "../../../components/Input";
import { patchMaintenance } from "../../../services/api";
import {
  ISSUE_LOCATIONS_OPTIONS,
  ISSUE_SEVERITY_OPTIONS,
  ISSUE_STATUS_OPTIONS,
  ISSUE_TYPE_OPTIONS,
  LOCATIONS,
  SEVERITIES,
  TYPES
} from "../constants";
import { useMaintenanceContext } from "../MaintenanceContainer";

const validationSchema = Yup.object().shape({
  summary: Yup.string().required("Please fill in summary"),
  home_code: Yup.string().required("Please fill in home code"),
  severity: Yup.mixed().oneOf(SEVERITIES, "Please choose severity"),
  type: Yup.mixed().oneOf(TYPES, "Please choose type"),
  location: Yup.mixed().oneOf(LOCATIONS, "Please choose location"),
  description: Yup.string().required("Please fill in description"),
  estimate: Yup.number().required("Please fill in estimate"),
  hide_from_host: Yup.mixed().oneOf(
    ["true", "false"],
    "Please choose one option"
  )
});

export const EditMaintenance = ({ maintenanceId, setEditMode }) => {
  const history = useHistory();
  const { data } = useMaintenanceContext();

  const initialValues = {
    summary: data.maintenance.summary || "",
    severity: data.maintenance.severity || null,
    type: data.maintenance.type || null,
    location: data.maintenance.location || null,
    description: data.maintenance.description || "",
    estimate: data.maintenance.estimate || "",
    hide_from_host: data.maintenance.hide_from_host || true,
    fee_type: data.maintenance.fee_type || "",
    supplier_cost: data.maintenance.supplier_cost || "",
    job_report: data.maintenance.job_report || "",
    resolution_notes: data.maintenance.resolution_notes || "",
    status: data.maintenance.status || null,
    proposed_solution: data.maintenance.proposed_solution || ""
  };

  const handleSubmit = values => {
    const backendFriendlyValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value ? value : null])
    );

    patchMaintenance(maintenanceId, backendFriendlyValues)
      .then(() => {
        history.push("/maintenance/success");
      })
      .catch(err => console.log(err));
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  return (
    <div className="">
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, isValid, errors, values, setFieldValue }) => (
          <Form className="MaintenanceForm Edit" data-testid="login-form">
            <div className="MaintenanceForm__inner MaintenanceInfoContainer">
              <p className="big-header">Details</p>

              <Input
                name="summary"
                component="input"
                type="text"
                label="Summary"
              />

              <Input name="type" component="select" type="text" label="Type">
                <option disabled value="">
                  Select type
                </option>
                {ISSUE_TYPE_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>

              <Input
                name="location"
                component="select"
                type="text"
                label="Location"
              >
                <option disabled value="">
                  Select location
                </option>
                {ISSUE_LOCATIONS_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>

              <Input
                name="severity"
                component="select"
                type="text"
                label="Severity"
              >
                <option disabled value="">
                  Select severity
                </option>
                {ISSUE_SEVERITY_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>

              <Input
                name="description"
                component="textarea"
                type="text"
                label="Description"
              />

              <p className="Input__label">Hide from host?</p>
              <div role="group" aria-labelledby="hide-from-host">
                <label className="Radio">
                  Yes
                  <Field
                    type="radio"
                    name="hide_from_host"
                    value="true"
                    id="yes"
                  />
                </label>

                <label className="Radio">
                  No
                  <Field type="radio" name="hide_from_host" value="false" />
                </label>
              </div>
            </div>

            <div className="MaintenanceForm__inner MaintenanceInfoContainer">
              <p className="big-header">Resolution</p>
              <Input
                name="estimate"
                component="input"
                type="number"
                label="Estimate cost (shared with host)"
              />

              <Input
                name="fee_type"
                component="input"
                type="text"
                label="Fee type"
              />

              <Input
                name="supplier_cost"
                component="input"
                type="number"
                label="Supplier cost"
              />

              <Input
                name="job_report"
                component="textarea"
                type="text"
                label="Job report"
              />

              <Input
                name="resolution_notes"
                component="textarea"
                type="text"
                label="Resolution notes"
              />
            </div>

            <div className="MaintenanceForm__inner MaintenanceInfoContainer">
              <p className="big-header">Status</p>
              <Input name="type" component="select" type="text" label="Type">
                <option disabled value="">
                  Select type
                </option>
                {ISSUE_STATUS_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>

              <Input
                name="proposed_solution"
                component="textarea"
                type="text"
                label="Proposed solution"
              />
            </div>

            <Button
              type="submit"
              buttonType="positive"
              handleOnClick={() => handleSubmit(values)}
            >
              Submit
            </Button>

            <Button type="button" handleOnClick={() => handleCancel(values)}>
              Cancel
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditMaintenance;
