import React from "react";

const DropDown = ({ options, handleSelection, defaultOption, label, name }) => {
  return (
    <React.Fragment>
      <strong>
        <label htmlFor={name}>{label}</label>
      </strong>
      <select onChange={handleSelection} value={defaultOption} id={name}>
        {options.map(option => (
          <option key={option.key} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
};

export default DropDown;
