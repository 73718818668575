import "./Inbox.scss";

import React from "react";

import Alert from "../../components/Alert";
import Loading from "../../components/Loading";
import { formatPropertyError } from "../../utils/format";
import Properties from "./components/Properties";
import ThreadPreview from "./components/ThreadPreview";

const Inbox = ({ state, mailBoxName, listingId, homeCode }) => {
  const { loading, error, data } = state;

  if (loading) {
    return <Loading message={`Loading inbox for ${mailBoxName}...`} />;
  }

  if (error) {
    const formattedMessage = formatPropertyError(error, mailBoxName);

    return (
      <div className="Inbox__alert">
        <Alert
          title={"Something went wrong..."}
          message={formattedMessage}
          status="fail"
          type="block"
        />
      </div>
    );
  }

  if (data) {
    return (
      <React.Fragment>
        <Properties properties={data.properties} listingId={listingId} />

        {data.threads.length === 0 ? (
          <p>No conversations found.</p>
        ) : (
          data.threads.map(thread => {
            return (
              <ThreadPreview
                key={thread.id}
                thread={thread}
                mailBoxName={mailBoxName}
                homeCode={homeCode}
              />
            );
          })
        )}
      </React.Fragment>
    );
  }
};

export default Inbox;
