import "./GuestDetails.scss";

import React from "react";

import { ReactComponent as ShieldCheck } from "../../../assets/icons/shield-check.svg";
import { ReactComponent as ShieldX } from "../../../assets/icons/shield-x.svg";
import { ReactComponent as Star } from "../../../assets/icons/star-filled.svg";
import GuestImage from "../../../components/GuestImage";
import Loading from "../../../components/Loading";
import Spinner from "../../../components/Spinner";
import { AIRBNB_USER_URL } from "../../../utils/constants";
import { useThreadContext } from "../ThreadContext";
import GuestFlag from "./GuestFlag";
import GuestFlagAlert from "./GuestFlagAlert";
import UnhappyGuest from "./UnhappyGuest";

const GuestDetails = () => {
  const {
    refreshing,
    refreshThread,
    airbnbThreadInfo,
    booking,
    reservation,
    guestSentiment
  } = useThreadContext();

  if (refreshing) {
    return <Loading message="Refreshing guest details..." />;
  }

  if (!airbnbThreadInfo.guest) {
    return null;
  }

  const { guest } = airbnbThreadInfo;
  const { id, first_name, location, about, picture_url } = guest;
  const status = reservation?.status;

  const guestVerified = () => {
    const verified = reservation?.guest?.verified;

    if (verified) {
      return (
        <div title="Guest is verified by Airbnb" data-testid="guest-verified">
          <ShieldCheck className="GuestDetails__icon GuestDetails__icon--verified" />
        </div>
      );
    }

    if (verified === false) {
      return (
        <div
          title="Guest has not been verified by Airbnb"
          data-testid="guest-verified"
        >
          <ShieldX className="GuestDetails__icon GuestDetails__icon--unverified" />
        </div>
      );
    }

    return null;
  };

  const linkToAirbnb = element => (
    <a
      href={`${AIRBNB_USER_URL}/${id}`}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="guest-link-to-airbnb"
    >
      {element}
    </a>
  );

  return (
    <div className="GuestDetails">
      <React.Fragment>
        <div className="GuestDetails__basic">
          {linkToAirbnb(
            <GuestImage url={picture_url} status={status} size="large" />
          )}

          <div>
            <div className="GuestDetails__guest">
              <h4 data-testid="guest-name">
                {linkToAirbnb(first_name)} {location && `from ${location}`}
              </h4>
              <div className="GuestDetails__verified">{guestVerified()}</div>
              <div className="GuestDetails__sentiment">
                {guestSentiment ? (
                  <>
                    <span className="GuestDetails__sentiment-star">
                      <Star />
                    </span>
                    {guestSentiment.sentiment_score}{" "}
                    {!(
                      guestSentiment.positive_reviews === null &&
                      guestSentiment.neutral_reviews === null &&
                      guestSentiment.negative_reviews === null
                    ) && (
                      <>
                        (
                        <span className="GuestDetails__reviews GuestDetails__reviews--positive">
                          {+guestSentiment.positive_reviews}
                        </span>
                        ,
                        <span className="GuestDetails__reviews GuestDetails__reviews--neutral">
                          {+guestSentiment.neutral_reviews}
                        </span>
                        ,
                        <span className="GuestDetails__reviews GuestDetails__reviews--negative">
                          {+guestSentiment.negative_reviews}
                        </span>
                        )
                      </>
                    )}
                  </>
                ) : (
                  <Spinner />
                )}
              </div>
            </div>

            <GuestFlag guest={guest} refreshThread={refreshThread} />
            <UnhappyGuest booking={booking} refreshThread={refreshThread} />
          </div>
        </div>

        {about && <p data-testid="guest-about">"{about}"</p>}
      </React.Fragment>

      <GuestFlagAlert flag={guest.flag} />
    </div>
  );
};

export default GuestDetails;
