import "./Maintenance.scss";

import { Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import Button from "../../components/Button";
import { Input } from "../../components/Input";
import { postMaintenance } from "../../services/api";
import {
  ISSUE_LOCATIONS_OPTIONS,
  ISSUE_SEVERITY_OPTIONS,
  ISSUE_TYPE_OPTIONS,
  LOCATIONS,
  SEVERITIES,
  TYPES
} from "./constants";

const validationSchema = Yup.object().shape({
  summary: Yup.string().required("Please fill in summary"),
  home_code: Yup.string().required("Please fill in home code"),
  severity: Yup.mixed().oneOf(SEVERITIES, "Please choose severity"),
  type: Yup.mixed().oneOf(TYPES, "Please choose type"),
  location: Yup.mixed().oneOf(LOCATIONS, "Please choose location"),
  description: Yup.string().required("Please fill in description"),
  estimate: Yup.number().required("Please fill in estimate"),
  hide_from_host: Yup.mixed().oneOf(
    ["true", "false"],
    "Please choose one option"
  )
});

export const NewMaintenance = () => {
  const history = useHistory();
  const initialValues = {
    summary: "",
    home_code: "",
    severity: null,
    type: null,
    location: null,
    description: "",
    estimate: "",
    hide_from_host: true
  };

  const handleSubmit = values => {
    postMaintenance(values)
      .then(() => {
        history.push("/maintenance/success");
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="Maintenance">
      <p className="Maintenance__title"> New maintenance issue</p>

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, isValid, errors, values, setFieldValue }) => (
          <Form className="MaintenanceForm" data-testid="login-form">
            <Input
              name="summary"
              component="input"
              type="text"
              label="Summary"
            />

            <Input
              name="home_code"
              component="input"
              type="text"
              label="Homecode"
            />

            <Input
              name="severity"
              component="select"
              type="text"
              label="Severity"
            >
              <option disabled value="">
                Select severity
              </option>
              {ISSUE_SEVERITY_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>

            <Input name="type" component="select" type="text" label="Type">
              <option disabled value="">
                Select type
              </option>
              {ISSUE_TYPE_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>

            <Input
              name="location"
              component="select"
              type="text"
              label="Location"
            >
              <option disabled value="">
                Select location
              </option>
              {ISSUE_LOCATIONS_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Input>

            <Input
              name="description"
              component="textarea"
              type="text"
              label="Description"
            />

            <Input
              name="estimate"
              component="input"
              type="number"
              label="Estimate cost (shared with host)"
            />

            <p className="Input__label">Hide from host?</p>
            <div role="group" aria-labelledby="extra-beds">
              <label className="Radio">
                Yes
                <Field
                  type="radio"
                  name="hide_from_host"
                  value="true"
                  id="yes"
                />
              </label>

              <label className="Radio">
                No
                <Field type="radio" name="hide_from_host" value="false" />
              </label>
            </div>

            <Button type="submit" buttonType="positive">
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewMaintenance;
