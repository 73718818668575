import "./SentMetaData.scss";

import { distanceInWordsStrict, format } from "date-fns";
import React from "react";

import { DATETIME_FORMAT } from "../utils/constants";

const SentMetaData = ({ datetime, actioned_by }) => {
  const TODAY = Date.now();

  return (
    <p className="SentMetaData" title={format(datetime, DATETIME_FORMAT)}>
      Sent {distanceInWordsStrict(datetime, TODAY)} ago{" "}
      {actioned_by && `by ${actioned_by}`}
    </p>
  );
};
export default SentMetaData;
