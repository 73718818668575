import "./Property.scss";

import React from "react";
import { Link } from "react-router-dom";

import {
  AIRBASE_PROPERTY_URL,
  AIRBNB_LISTING_URL
} from "../../../utils/constants";

const Property = ({ property, listingId, showFilters = true }) => {
  const displayLink = (url, title) => (
    <a
      className="Property__link"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {title}
    </a>
  );

  return (
    <div key={property.id} className="Property__item">
      {displayLink(
        `${AIRBASE_PROPERTY_URL}/${property.id}`,
        property.home_code
      )}

      {displayLink(
        `${AIRBNB_LISTING_URL}/${property.airbnb_id}`,
        "Airbnb listing"
      )}

      {showFilters &&
        (listingId === property.airbnb_id ? (
          <Link
            className={`Property__link Property__link--reset`}
            to={`/mailbox/${property.airbnb_login}`}
            title="Show all threads on this account"
          >
            Show all threads
          </Link>
        ) : (
          <Link
            className={`Property__link Property__link--set`}
            to={`/mailbox/${property.airbnb_login}/listing/${property.airbnb_id}`}
            title="Show threads from this listing only"
          >
            Filter threads
          </Link>
        ))}
    </div>
  );
};

export default Property;
