import get from "lodash/get";
import React from "react";

import Alert from "../../../components/Alert";
import { useThreadContext } from "../ThreadContext";

const HostInstructions = () => {
  const { property } = useThreadContext();

  if (!property) {
    return null;
  }

  const hostInstructions = get(
    property,
    "host_preferences.special_guidelines",
    null
  );

  if (!hostInstructions) {
    return null;
  }

  return (
    <div className="HostInstructions" data-testid="host-instructions">
      <Alert title="Host instructions" message={hostInstructions} />
    </div>
  );
};

export default HostInstructions;
