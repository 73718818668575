import axios from "axios";
import get from "lodash/get";
import React, { Component } from "react";

import {
  getBookingsByParams,
  getBookingsyncConversation,
  searchProperties
} from "../../services/api";
import Search from "./Search.js";

export default class SearchContainer extends Component {
  state = {
    loading: true,
    error: false,
    data: null,
    bookingsyncConversation: null
  };

  componentDidUpdate(nextProps) {
    const { guestEmail } = this.props.match.params;

    if (guestEmail !== nextProps.match.params.guestEmail) {
      this.cancelPendingRequest("A new search was requested.");
      this.getSearchResults();
    }
  }

  componentDidMount() {
    this.getSearchResults();
  }

  componentWillUnmount() {
    this.cancelPendingRequest(
      "This request was pending after the component unmounted."
    );
  }

  cancelPendingRequest = message => {
    this.cancelRequest.cancel(message);
    this.cancelRequest = null;
  };

  updateThread = () => {
    const { conversationId } = this.props.match.params;

    if (conversationId) {
      getBookingsyncConversation(conversationId, this.cancelRequest.token).then(
        ({ data: bookingsyncConversation }) => {
          this.setState({
            bookingsyncConversation
          });
        }
      );
    }
  };

  getSearchResults = () => {
    this.cancelRequest = axios.CancelToken.source();
    this.setState({ loading: true });
    const { conversationId } = this.props.match.params;

    const {
      guestEmail,
      vrboListingId,
      expediaListingId,
      platformRef
    } = this.props.match.params;

    let getData;
    if (guestEmail || platformRef) {
      getData = getBookingsByParams;
    } else if (vrboListingId || expediaListingId) {
      getData = searchProperties;
    }

    if (getData) {
      getData(
        {
          guest_email: guestEmail,
          platform_ref: platformRef,
          vrbo_listing_id: vrboListingId,
          expedia_listing_id: expediaListingId
        },
        this.cancelRequest.token
      )
        .then(({ data }) => {
          this.setState({
            data: data,
            loading: !!conversationId
          });
          if (conversationId) {
            getBookingsyncConversation(
              conversationId,
              this.cancelRequest.token
            ).then(({ data: bookingsyncConversation }) => {
              this.setState({
                bookingsyncConversation,
                loading: false
              });
            });
          }
        })
        .catch(error => {
          if (get(error, "response.data.status_code", null) === 404) {
            return this.props.history.push("/not-found");
          }

          if (axios.isCancel(error)) {
            return console.info(error.message);
          }

          this.setState({
            loading: false,
            error
          });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Search state={this.state} update={this.updateThread} />
      </React.Fragment>
    );
  }
}
