import "./CancellationReason.scss";

import React, { useContext, useState } from "react";

import Alert from "../../../components/Alert";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import { AppContext } from "../../../data/AppContextContainer";
import { cancelReservation } from "../../../services/api";
import {
  CANCELLATION_REASONS_DESCRIPTIONS,
  CANCELLATION_SUBREASONS_DESCRIPTIONS
} from "../../../utils/constants";

const CancellationReason = ({
  applicableReasons,
  mailBoxName,
  confirmationCode,
  onCancel,
  refreshThread
}) => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reason, setReason] = useState("");
  const [subReason, setSubReason] = useState("");
  const [messageToGuest, setMessageToGuest] = useState("");
  const [messageToAirbnb, setMessageToAirbnb] = useState("");

  const subReasons = () => {
    if (!reason) {
      return [];
    }

    return applicableReasons.find(ar => ar.reason === reason).sub_reasons;
  };

  const requireSubReason = () => {
    if (!reason) {
      return false;
    }

    return !!subReasons().length;
  };

  const isValid = () => {
    if (!reason || messageToGuest.length < 15 || messageToAirbnb.length < 15) {
      return false;
    }

    // Some reasons do not require sub-reasons
    return !(requireSubReason() && !subReason);
  };

  const handleReasonChanged = event => {
    event.preventDefault();
    // Reset sub-reasons when reason changes
    setSubReason("");
    setReason(event.target.value);
  };

  const handleCancellation = event => {
    event.preventDefault();

    const cancellationData = {
      reason: reason,
      sub_reason: subReason,
      message_to_guest: messageToGuest,
      message_to_airbnb: messageToAirbnb
    };

    setLoading(true);

    cancelReservation(mailBoxName, confirmationCode, cancellationData)
      .then(() => {
        // Hide modal first
        onCancel();
        // Then refresh the page
        refreshThread();
        // And display the pop-up at the same time
        context.showNotification({
          title: "Reservation cancelled!"
        });
      })
      .catch(e => {
        setError(`Something went wrong when cancelling the reservation - ${e}`);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <Loading message={"Cancelling reservation..."} />;
  }

  if (error) {
    return (
      <>
        <Alert title="Something went wrong..." message={error} status="fail" />
        <Button handleOnClick={onCancel}>Close</Button>
      </>
    );
  }

  return (
    <div className="CancellationReason">
      <div className="CancellationReason__header">
        <strong>Please fill in required fields:</strong>
      </div>
      <div className="CancellationReason__main">
        <div className="CancellationReason__main__item">
          <label htmlFor="reason">Main reason</label>
          <select
            id="reason"
            data-testid="input-reason"
            value={reason}
            onChange={handleReasonChanged}
          >
            <option disabled value="" />
            {applicableReasons.map(ar => (
              <option key={ar.reason} value={ar.reason}>
                {CANCELLATION_REASONS_DESCRIPTIONS[ar.reason] || ar.reason}
              </option>
            ))}
          </select>
        </div>
        {requireSubReason() && (
          <div className="CancellationReason__main__item">
            <label htmlFor="subReason">Sub-reason</label>
            <select
              id="subReason"
              data-testid="input-subreason"
              value={subReason}
              onChange={e => setSubReason(e.target.value)}
            >
              <option disabled value="" />
              {subReasons().map(subReason => (
                <option key={subReason} value={subReason}>
                  {CANCELLATION_SUBREASONS_DESCRIPTIONS[subReason] || subReason}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="CancellationReason__main__item">
          <label htmlFor="messageToGuest">Message to guest</label>
          <textarea
            id="messageToGuest"
            data-testid="input-guest"
            value={messageToGuest}
            onChange={e => setMessageToGuest(e.target.value)}
          />
        </div>
        <div className="CancellationReason__main__item">
          <label htmlFor="messageToAirbnb">Message to Airbnb</label>
          <textarea
            id="messageToAirbnb"
            data-testid="input-airbnb"
            value={messageToAirbnb}
            onChange={e => setMessageToAirbnb(e.target.value)}
          />
        </div>
        <div className="CancellationReason__footer">
          <Button
            data-testid="submit-button"
            buttonType="positive"
            disabled={!isValid()}
            handleOnClick={handleCancellation}
          >
            Cancel reservation
          </Button>
          <Button buttonType="negative" handleOnClick={onCancel}>
            I reconsidered
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CancellationReason;
