// Move here all status related functions from components

import { addMinutes } from "date-fns";

import { naiveToUtc } from "./datetime";

export const getPendingRequestStatus = thread => {
  if (!thread || thread.posts.length === 0 || !thread.posts[0].action_type) {
    return null;
  }

  // Up to a minute after request there might be a discrepancy between Airbnb and db data.
  // If latest action in this thread was related to an alteration then display information
  // that this alteration is no longer actionable.
  const oneMinuteAgo = addMinutes(Date.now(), -1);
  const requested_at = new Date(naiveToUtc(thread.posts[0].created_at));

  if (
    thread.posts[0].action_type.startsWith("Alteration") &&
    requested_at > oneMinuteAgo
  ) {
    return thread.posts[0].action_type;
  }

  return null;
};
