import "./Inbox.scss";

import axios from "axios";
import React, { Component } from "react";

import AppHeader from "../../components/AppHeader";
import { getMailBox } from "../../services/api";
import Inbox from "./Inbox";

export default class InboxContainer extends Component {
  state = {
    loading: true,
    error: false,
    data: null
  };

  componentDidUpdate(prevProps) {
    const { mailBoxName, listingId } = this.props.match.params;

    if (
      mailBoxName !== prevProps.match.params.mailBoxName ||
      listingId !== prevProps.match.params.listingId
    ) {
      this.cancelPendingRequest("A newer request was made.");
      this.getInbox();
    }
  }

  componentDidMount() {
    this.getInbox();
  }

  componentWillUnmount() {
    this.cancelPendingRequest(
      "This request was pending after the component unmounted."
    );
  }

  cancelPendingRequest(message) {
    this.cancelRequest.cancel(message);
    this.cancelRequest = null;
  }

  getInbox() {
    this.cancelRequest = axios.CancelToken.source();
    this.setState({ loading: true });

    const { mailBoxName, listingId } = this.props.match.params;

    getMailBox(mailBoxName, listingId, this.cancelRequest.token)
      .then(({ data }) => {
        this.setState({
          data,
          loading: false
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return console.info(error.message);
        }

        this.setState({
          loading: false,
          error
        });
      });
  }

  render() {
    const { mailBoxName, listingId, homeCode } = this.props.match.params;

    return (
      <React.Fragment>
        <AppHeader title={`Inbox for: ${mailBoxName}`} />

        <Inbox
          state={this.state}
          mailBoxName={mailBoxName}
          listingId={listingId}
          homeCode={homeCode}
        />
      </React.Fragment>
    );
  }
}
