import get from "lodash/get";
import React from "react";

import { getNumberOfDays } from "../../utils/datetime";

export const LengthOfStay = ({ checkin, checkout, property }) => {
  const LONG_STAY_THRESHOLD = 31;

  if (!checkin && !checkout) {
    return null;
  }

  const lengthOfStay =
    checkin && checkout ? getNumberOfDays(checkout, checkin) : null;
  const acceptsLongStay = get(
    property,
    "host_preferences.accept_long_bookings"
  );

  let nightModifierClass = "success";

  if (lengthOfStay >= LONG_STAY_THRESHOLD && acceptsLongStay === "contact") {
    nightModifierClass = "warning";
  }

  return (
    <dl className="BookingDetails__nights" data-testid="length-of-stay">
      <dt className="label-info">Nights</dt>

      <dd className="BookingDetails__info">
        <span
          className={`BookingDetails__nights-number BookingDetails__nights-number--${nightModifierClass}`}
          data-testid={`length-of-stay-value-${nightModifierClass}`}
        >
          {lengthOfStay}
        </span>
      </dd>
    </dl>
  );
};
