import {
  LANGUAGE_ENGLISH,
  MESSAGE_GREETINGS,
  SUPPORTED_LANGUAGES
} from "../../utils/constants";

export const propertyLanguage = property => {
  let language = property?.locality?.language;

  if (!SUPPORTED_LANGUAGES.includes(language)) {
    language = LANGUAGE_ENGLISH;
  }

  return language;
};

export const generateMessageTemplate = (
  property,
  guestName,
  cannedMessages = []
) => {
  const language = propertyLanguage(property);
  const { salutation, signOff } = MESSAGE_GREETINGS[language];
  const recipient = guestName ? ` ${guestName}` : "";
  const cannedMessage = cannedMessages.join("\n\n");
  const content = cannedMessage || "";

  return `${salutation + recipient},\n\n${content}\n\n${signOff}`;
};

export const possibleEmptyTemplates = (property, guestName) => {
  const guestNamesTemplates = [guestName, ""].map(name =>
    generateMessageTemplate(property, name)
  );

  return [...guestNamesTemplates, "", null];
};

export const securelyParseJson = json => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};
